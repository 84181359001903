import anime from 'animejs/lib/anime.es';
// import { TweenLite, CSSPlugin } from 'gsap';
import { throttle } from '../vendors/helpers/throttle';


export default class Header {

  constructor(debug = false)
  {
    this.TRANSITION_TIME      = 300;
    this.THROTTLE_DELAY       = 50;
    this.IDLE_DETECTION_DELAY = 50;
    this.lastScrollTop        = window.scrollTop();
    this.lastBusyScrollTop    = window.scrollTop();
    this.scrollUp             = false;
    this.headerBottomAnim     = false; // for menus with only one row
    this.runningFromRes       = 1024;

    this.isRunning = {
      hideTop: false,
      showTop: false,
      hideBot: false,
      showBot: false,
    };

    this.debug = debug;
    if (this.debug) console.log('Header init');

    if (!this.setVars()) return;
    this.setEvents();

    if (this.debug) console.log('Header succesful');
  }

  setVars()
  {
    this.header = document.querySelector('.header');
    if (!this.header) return;

    this.headerTopEl = document.querySelector('[data-header-top]');
    if (!this.headerTopEl) return;

    this.headerBotEl = document.querySelector('[data-header-bottom]');
    if (!this.headerBotEl) return;

    // optional elements (like scroll to top button)
    //   that should get 'active' class when header is in 'sticky' mode
    this.activeElArr = document.querySelectorAll('[data-header-element-active]');


    this.classes = {
      sticky:   'header--sticky',
      stickyUp: 'header--stickyUp',
    };

    this.headerTop = {
      el: this.headerTopEl,
      max: 60,
      min: 0,
    };

    this.headerBot = {
      el: this.headerBotEl,
      max: 120,
      min: 30,
    };

    this.mobile = false;

    return true;
  }

  setEvents()
  {
    const mediaMatch = window.matchMedia(`(max-width: ${this.runningFromRes}px)`);
    if (mediaMatch.matches) {
      this.mobile = true;
      this.classes.sticky   += 'Mobile';
      this.classes.stickyUp += 'Mobile';
    }

    window.addEvent('load', () => {
      this.headerSticky();
    });

    window.addEvent('load', () => {
      setTimeout(() => {
        this.headerSticky();

        window.addEvent('scroll.Header', throttle(() => {
          this.headerSticky();
          // if (this.debug) console.info('Header throttling...', new Date().toUTCString());
        }, this.THROTTLE_DELAY));
      }, 0);
    });

    this.initMenuOnTopActions();
  }

  initMenuOnTopActions()
  {
    this.idleInterval = setInterval(() => {
      const scrollDelta = window.scrollTop() - this.lastBusyScrollTop;

      this.minimizeHeaderIfNotOnTop(scrollDelta);
      this.maximizeHeaderIfNotOnTop(scrollDelta);

      this.lastBusyScrollTop = window.scrollTop();

    }, this.IDLE_DETECTION_DELAY);
  }

  maximizeHeaderIfNotOnTop(scrollDelta)
  {
    if (window.scrollTop() === 0 && scrollDelta === 0
      && this.header.hasClass(this.classes.sticky)) {

      this.header.removeClass(this.classes.sticky);
      this.toggleActiveOnDepenedentElems('remove');

      this.showHeaderTop();
      this.showHeaderBottom();
    }
  }

  minimizeHeaderIfNotOnTop(scrollDelta)
  {
    if (window.scrollTop() !== 0 && scrollDelta === 0) {

      this.hideHeaderBottom();

      if (!this.header.hasClass(this.classes.sticky) && window.scrollTop() !== 0) {
        this.header.addClass(this.classes.sticky);
        this.toggleActiveOnDepenedentElems('add');
        this.hideHeaderTop();
      }
    }
  }

  headerSticky()
  {
    const MIMUM_DELTA = 50;

    const currentScrollTop = window.scrollTop();
    const scrollDelta = currentScrollTop - this.lastScrollTop;

    // show top menu on scroll up
    if (scrollDelta > 0) {
      if (scrollDelta > MIMUM_DELTA) {
        if (this.debug) console.log('going down (change)');

        this.header.removeClass(this.classes.stickyUp);
        this.header.addClass(this.classes.sticky);
        this.toggleActiveOnDepenedentElems('add');

        this.scrollUp = false;

        this.hideHeaderTop();
      }
    }
    else if (Math.abs(scrollDelta) > MIMUM_DELTA) {
      if (this.debug) console.log('going up (change)');

      this.header.addClass(this.classes.stickyUp);

      this.scrollUp = true;

      this.showHeaderTop();
    }

    this.lastScrollTop = currentScrollTop;
  }


  hideHeaderTop()
  {
    if (!this.mobile
      && this.isRunning.hideTop === false
      && this.headerTop.el.style.maxHeight !== `${this.headerTop.min}px`) {

      anime({
        targets: this.headerTop.el,
        translateY: -1 * parseInt(this.headerTop.max),
        duration: this.TRANSITION_TIME,
        easing: 'easeInOutQuad',
        begin: (/* anim */) => {
          this.isRunning.hideTop = true;
          if (this.debug) console.log('began hideTop');
        },
        complete: (/* anim */) => {
          this.isRunning.hideTop = false;
          if (this.debug) console.log('completed hideTop');
        },
      });
    }
  }

  hideHeaderBottom()
  {
    if (!this.mobile
      && this.headerBottomAnim && this.isRunning.hideBot === false
      && this.headerBot.el.style.maxHeight !== `${this.headerBot.min}px`) {

      anime({
        targets: this.headerBot.el,
        translateY: -1 * parseInt(this.headerBot.min),
        // maxHeight: this.headerBot.min,
        duration: this.TRANSITION_TIME,
        easing: 'easeInOutQuad',
        begin: (/* anim */) => {
          this.isRunning.hideBot = true;
          if (this.debug) console.log('began hideBot');
        },
        complete: (/* anim */) => {
          this.isRunning.hideBot = false;
          if (this.debug) console.log('completed hideBot');
        },
      });
    }
  }

  showHeaderTop()
  {
    if (!this.mobile
      && this.isRunning.showTop === false
      && this.headerTop.el.style.maxHeight !== `${this.headerTop.max}px`) {

      anime({
        targets: this.headerTop.el,
        translateY: this.headerTop.min,
        duration: this.TRANSITION_TIME,
        easing: 'easeInOutQuad',
        begin: (/* anim */) => {
          this.isRunning.showTop = true;
          if (this.debug) console.log('began showTop');
        },
        complete: (/* anim */) => {
          this.isRunning.showTop = false;
          if (this.debug) console.log('completed showTop');
        },
      });
    }
  }

  showHeaderBottom()
  {
    if (!this.mobile
      && this.headerBottomAnim && this.isRunning.showBot === false
      && this.headerBot.el.style.maxHeight !== `${this.headerBot.max}px`) {

      anime({
        targets: this.headerBot.el,
        translateY: this.headerBot.max,
        // maxHeight: this.headerBot.max,
        duration: this.TRANSITION_TIME,
        easing: 'easeInOutQuad',
        begin: (/* anim */) => {
          this.isRunning.showBot = true;
          if (this.debug) console.log('began showBot');
        },
        complete: (/* anim */) => {
          this.isRunning.showBot = false;
          if (this.debug) console.log('completed showBot');
        },
      });
    }
  }

  toggleActiveOnDepenedentElems(mode)
  {
    if (!this.activeElArr.length) return;

    if (mode === 'remove') {
      this.activeElArr.forEach(el => {
        el.removeClass('active');
      });
    }
    else if (mode === 'add') {
      this.activeElArr.forEach(el => {
        el.addClass('active');
      });
    }
  }
}
