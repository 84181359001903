export default class ScriptLoader {
  constructor(options) {
    const {
      script, player, global, debug, protocol = document.location.protocol,
    } = options;

    this.debug = debug;
    this.script = script;
    this.player = player;
    this.global = global;
    this.protocol = protocol;
    this.isLoaded = false;
  }

  loadScript() {
    this.debug && console.log('[scriptLoader] loadScript');

    return new Promise((resolve, reject) => {
      // Create script element and set attributes
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = `${this.protocol}//${this.script}`;

      // Append the script to the DOM
      const el = document.getElementsByTagName('script')[0];
      el.parentNode.insertBefore(script, el);

      // Resolve the promise once the script is loaded
      if (this.player === 'youtube') {
        window.onYouTubePlayerAPIReady = () => {
          this.isLoaded = true;
          resolve(script);
        };
      } else {
        script.addEventListener('load', () => {
          this.isLoaded = true;
          resolve(script);
        });
      }

      // Catch any errors while loading the script
      script.addEventListener('error', () => {
        this.debug && console.log('[scriptLoader] loadScript - error event');
        reject(new Error(`${this.script} failed to load.`));
      });
    });
  }

  loadIfNeeded() {
    return new Promise(async (resolve, reject) => {
      if (!this.isLoaded) {
        this.debug && console.log('[scriptLoader] loadIfNeeded - needed');
        try {
          await this.loadScript()
            .then((script) => {
              window[this.global] = this;
              resolve(script);
            })
            .catch((e) => {
              reject(e);
            });
        } catch (e) {
          reject(e);
        }
      } else {
        this.debug && console.log('[scriptLoader] loadIfNeeded - not needed');
        resolve(window[this.global]);
      }
    });
  }
}
