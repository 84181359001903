<template>
  <div>
    <div class="videoPlayerModal__player" data-modal-video-player-player>
      <div class="videoPlayerModal__playerEmbed">
        <!-- <div
          v-if="!videoActiveId"
          class="videoPlayerModal__playerWrapper videoPlayerModal__playerWrapper--empty"
        >
          - no video provided -
        </div> -->
      </div>
    </div>

    <div class="videoPlayerModal__controls">
      <div
        v-if="closeVisible"
        class="videoPlayerModal__close"
        :class="styleGalleryClose"
      >
        <button
          v-if="closeVisible"
          class="button button--close button--green button--dot"
          @click.prevent="click('close')"
        ></button>
      </div>
    </div>
  </div>
</template>


<script>
  /**
    Activated on custom event with video URL sent in a payload:
      const event = new CustomEvent('videoModalShow', { detail: videoUrl });
      window.dispatchEvent(event);

    ...preferably sent on click from:
      <a class="jsModalVideoPlayer" data-video-url="https://www.youtube.com/watch?v=9jWMXNDOkrc"></a>

    Requires:
      - scriptLoader (for clean embeding scripts in page on demand)

      - modal container (preferably in footer):
          <div id="modal-video-player" class="videoPlayerModal" data-modal-video-player-target></div>

      - portal ("@linusborg/vue-simple-portal) to modal container embeding the video-player:

        <portal selector="#modal-video-player">
          <div class="videoPlayerModal__inner">
            <video-player
              class="videoPlayerModal__component"
              video-url=""
            ></video-player>
          </div>
        </portal>
  */


  // eslint-disable-next-line no-unused-vars
import { debug, debugVerbose } from '../../debug';
import ScriptLoader from '../../../js/vendors/helpers/scriptLoader';

export default {
  props:
    {
      // gallery buttons additional style
      videoUrl: {
        type: String,
        default: '',
      },
      styleGalleryClose: {
        type: String,
        default: '',
      },
    },
  data() {
    return {
      closeVisible:             true,

      modalContainerAttr:       'data-modal-video-player-target',
      modalPlayerContainerAttr: 'data-modal-video-player-player',
      modalContainer:           null,
      modalPlayerContainer:     null,
      videoPadding:             40, // screent paddding in px

      player: null,

      apiLoad: {
        retryTimeout: 15, // how often check if API has loaded [ms]
        totalTimeout: 3000, // how long wait for API load [ms]
      },

      apiUrl: {
        youtube: 'www.youtube.com/player_api',
        vimeo:   'player.vimeo.com/api/player.js',
      },

      apiActions: {
        youtube: {
          pause:   'pauseVideo',
          destroy: 'destroy',
          getTime: 'getCurrentTime',
        },
        vimeo: {
          pause:   'pause',
          destroy: 'destroy',
          getTime: 'getCurrentTime',
        },
      },

      apiReady: {
        youtube: false,
        vimeo:   false,
      },

      videoTypes: [
        {
          regex: new RegExp('^.*(youtu.be/|v/|embed/|v=|^)([a-zA-Z0-9-_]{11}).*$'),
          type: 'youtube',
        },
        {
          regex: new RegExp('^.*(vimeo.com/|video/|^)([0-9]{6,11}).*$'),
          type: 'vimeo',
        },
      ],

      videoActiveId: null,

      videos: {},
        // activeVideoId: null,

      detect: {
        youtube: () => (typeof window.YT !== 'undefined')
          && (typeof window.YT.Player !== 'undefined'),
        vimeo: () => (typeof window.Vimeo !== 'undefined')
          && (typeof window.Vimeo.Player !== 'undefined'),
      },
    };
  },

  // computed: {
  //   textDirection: function () {
  //     return getComputedStyle(document.querySelector('html')).direction;
  //   },
  // },

  mounted() {
    debugVerbose && console.log('[VideoPlayer.vue] mounted');

    if (!this.setupContainers()) return;
    this.setupVideoBootEvent();
  },

  methods: {

    setupContainers() {
      debugVerbose && console.log('[VideoPlayer.vue] - setupContainers');

      this.modalContainer = document.querySelector(`[${this.modalContainerAttr}]`);
      this.modalPlayerContainer = document.querySelector(`[${this.modalPlayerContainerAttr}] > *`);

      return this.modalPlayerContainer && this.modalContainer;
    },

    setupVideoBootEvent() {
      window.addEvent('videoModalShow', (e) => {
        this.bootVideo(e);
      });
    },

    bootVideo(e) {
      debugVerbose && console.log('[VideoPlayer.vue] - bootVideo', e.detail);
      if (!e.detail) return;

      this.showModal();

      const videoId = this.setupVideoInfo(e.detail);
      debugVerbose && console.log('[VideoPlayer.vue] - bootVideo - videoId', videoId);
      if (!videoId) return;

      this.initVideo(videoId);
    },


    showModal() {
      debugVerbose && console.log('[VideoPlayer.vue] showModal');

      document.body.addClass('modal');
      this.modalContainer && this.modalContainer.addClass('active');
    },

    hideModal() {
      debugVerbose && console.log('[VideoPlayer.vue] hideModal');

      document.body.removeClass('modal');
      this.modalContainer && this.modalContainer.removeClass('active');
    },

    setupVideoInfo(videoUrl) {
      debugVerbose && console.log('[VideoPlayer.vue] - setupVideoInfo', videoUrl);

      this.videoTypes.forEach((videoType) => {

        const matches = videoUrl.match(videoType.regex);

        if (matches) {
          [,, this.videoActiveId] = matches;
          debugVerbose && console.log('[VideoPlayer.vue] - setupVideoInfo 1', this.videoActiveId);

          if (!this.videos[this.videoActiveId]) {
            this.videos[this.videoActiveId] = {
              id:   matches[2],
              url:  videoUrl,
              type: videoType.type,
              time: 0,
            };
            debugVerbose && console.log('[VideoPlayer.vue] - setupVideoInfo 2', this.videos);
          }
        }
      });

      return this.videoActiveId;
    },


    initVideo(videoId) {
      debugVerbose && console.log('[VideoPlayer.vue] - initVideo', videoId);
      const video = this.videos[videoId];

      if (!this.isVideoParamsOK(videoId)) return;

      if (this.apiReady[video.type]) {
        this.playVideo(video);
      } else {
        this.loadPlayerScriptAsync(video);
      }
    },


    isVideoParamsOK(videoId) {
      if (!videoId || !this.videos[videoId].id) {
        return this.showMessage('Unknown video Id.');
      }
      if (!this.modalPlayerContainer) {
        return this.showMessage('Player DOM container not found.');
      }
      if (this.apiUrl[this.videos[videoId].type] === undefined) {
        return this.showMessage('Unkonwn video type.');
      }

      return true;
    },

    async loadPlayerScriptAsync(video) {
      const loader = new ScriptLoader({
        protocol: 'https:',
        script:   this.apiUrl[video.type],
        player:   video.type,
        global:   `videoPlayer_${video.type}`,
        debug:    false,
      });

      await loader.loadIfNeeded()
        .then((info) => {
          debugVerbose && console.log('[VideoPlayer.vue] - player loaded',
            info, window.videoPlayer_youtube);
          this.apiReady[video.type] = true;
          this.playVideo(video);
        })
        .catch((error) => {
          console.log('[VideoPlayer.vue] - err loading player script', error);
          this.showMessage(`Error loading video player script (${error}).`);
        });
    },


    playVideo(video) {
      debugVerbose && console.log('[VideoPlayer.vue] - playVideo', video, video.type);

      switch (video.type) {
      case 'youtube':
        this.playYoutubeVideo(video);
        return;
      case 'vimeo':
        this.playVimeoVideo(video);
        return;
      default:
        this.showMessage(`Video type not supported, video: ${video}`);
      }
    },


    // https://developers.google.com/youtube/iframe_api_reference?hl=pl
    playYoutubeVideo(video) {
      debugVerbose && console.log('[VideoPlayer.vue] - playYoutubeVideo',
        video, this.videos[video.id] ? this.videos[video.id].time : 0);

      this.modalPlayerContainer.innerHTML = '';
      this.player = new window.YT.Player(this.getCleanEmbedNode(), {
        videoId: video.id,
        playerVars: {
          autoplay: 1,
          modestbranding: 1,
          controls: 1,
          loop: 0,
          rel: 0,
          showinfo: 0,
          // start: this.videos[video.id] ? this.videos[video.id].time : 0,
          // ecver: 2,
          origin: `${window.location.protocol}//${window.location.hostname}`,
        },
        events: {
          onReady: () => {
            const [newWidth, newHeight] = this.getVideoMaxSize(this.player);
            const mediaMobileMatch = window.matchMedia('(max-width: 600px)');

            this.player.setSize(newWidth, newHeight);
            this.player.setPlaybackQuality(mediaMobileMatch.matches ? 'large' : 'hd720');
            this.player.seekTo(this.videos[video.id] ? this.videos[video.id].time : 0);
            this.player.playVideo();
          },
        },
      });
    },


    // https://developer.vimeo.com/player/sdk/reference#pause-a-video
    playVimeoVideo(video) {
      debugVerbose && console.log('[VideoPlayer.vue] - playVimeoVideo', video);

      const [newWidth, newHeight] = this.getVideoMaxSize();

      this.player = new window.Vimeo.Player(this.getCleanEmbedNode(), {
        id:          video.id,
        autopause:   true,
        autoplay:    true,
        loop:        false,
        background:  false,
        transparent: true,
        width:       newWidth,
        height:      newHeight,
        // responsive:  true,
      });

      this.player.on('error', (err) => {
        console.log('[VideoPlayer.vue] - playVimeoVideo ERROR', err);
        this.showMessage(`Vimeo player error<br>${error}`);
      });

      this.player.ready()
        .then(() => {
          console.log('[VideoPlayer.vue] - READY');

          debugVerbose && console.log('[VideoPlayer.vue] - playVimeoVideo 1', newWidth, newHeight);

          this.player.setCurrentTime(this.videos[video.id] ? this.videos[video.id].time : 0)
            .then((seconds) => {
              debugVerbose && console.log('[VideoPlayer.vue] - vimeo timeset - seconds', seconds);
            })
            .catch((error) => {
              debugVerbose && console.log('[VideoPlayer.vue] - vimeo timeset error ', error);
              this.showMessage(`Vimeo timeset error<br>${error}`);
            });

          // this.player
          // .play()
          // .then((ret) => {
          //   debugVerbose && console.log('[VideoPlayer.vue] - vimeo play', ret);
          //   // this.player.play();
          // })
          // .catch((error) => {
          //   debugVerbose && console.log('[VideoPlayer.vue] - vimeo playing error ', error);
          //   this.showMessage(`Vimeo playing error<br>${error}`);
          // });
        });
    },


    getCleanEmbedNode() {
      debugVerbose && console.log('[VideoPlayer.vue] - getCleanEmbedNode');

      const embedNode = document.querySelector(`[${this.modalPlayerContainerAttr}] > div`);

      // node is now cleaned by the player.destroy  method so probably not needed anymore

      // const embedNodeWrapper = document.querySelector('[' + this.modalPlayerContainerAttr + ']');
      // embedNodeWrapper.querySelectorAll('*').forEach(n => n.parentNode.removeChild(n));

      // const embedNode = embedNodeWrapper.appendChild(document.createElement("div"));
      // embedNode.addClass('videoPlayerModal__playerEmbed');

      return embedNode;
    },


    getVideoMaxSize() {
      this.modalPlayerContainer = document.querySelector(`[${this.modalPlayerContainerAttr}] > *`);
      // eslint-disable-next-line no-magic-numbers
      if (!this.modalPlayerContainer) return [320, 240];

      const documentWidth = (window.innerWidth
        || document.documentElement.clientWidth || document.body.clientWidth) - this.videoPadding;
      const documentHeight = (window.innerHeight
        || document.documentElement.clientHeight || document.body.clientHeight) - this.videoPadding;

      const containerSize = {
        width:  documentWidth,
        height: Math.round(documentWidth * this.modalPlayerContainer.offsetHeight
          / this.modalPlayerContainer.offsetWidth),
      };

      if (containerSize.height > documentHeight) {
        containerSize.width  = Math.round(documentHeight * this.modalPlayerContainer.offsetWidth
          / this.modalPlayerContainer.offsetHeight);
        containerSize.height = documentHeight;
      }

      debugVerbose && console.log('[VideoPlayer.vue] - getVideoMaxSize',
        containerSize.width, containerSize.height);

      return [
        containerSize.width,
        containerSize.height,
      ];
    },


      // videoPauseHandler()
      // {
      //   debugVerbose && console.log('[VideoPlayer.vue] - videoPauseHandler');
      // },


    click(mode) {
      switch (mode) {
      case 'close':
        if (this.player) {
          const videoType = this.videos[this.videoActiveId].type;

          this.player[this.apiActions[videoType].pause]();
          this.videos[this.videoActiveId].time = this.player[this.apiActions[videoType].getTime]();

          if (typeof this.videos[this.videoActiveId].time === 'object') {
            this.videos[this.videoActiveId].time
              .then((seconds) => {
                debugVerbose && console.log('[VideoPlayer.vue] - close (vimeo) / pause at',
                  seconds);
                this.videos[this.videoActiveId].time = seconds;
                this.player[this.apiActions[videoType].destroy]();
              })
              .catch((error) => {
                debugVerbose && console.log('[VideoPlayer.vue] - promise catch', error);
                this.videos[this.videoActiveId].time = 0;
              });
          } else {
            debugVerbose && console.log('[VideoPlayer.vue] - close (youtube) / pause at',
              this.videos[this.videoActiveId].time);
            this.player[this.apiActions[videoType].destroy]();
          }
        }

        this.hideModal();

        break;
      default:
      }
    },


    showMessage(message) {
      this.modalPlayerContainer = document.querySelector(
        `[${this.modalPlayerContainerAttr}] > *`
      );
      if (!this.modalPlayerContainer) return false;
      this.modalPlayerContainer.innerHTML = message;

      return false;
    },


    // apiLoaded() {
    //   let retryTime = 0;

    //   const apiPromise = new Promise((resolve, reject) => {
    //     const apiLoadedCheck = () => {
    //       if (this.detect[this.videos[this.videoActiveId].type]()) {
    //         resolve();
    //       } else {
    //         retryTime += this.apiLoad.retryTimeout;
    //         if (retryTime > this.apiLoad.totalTimeout) {
    //           debugVerbose && console.log('[VideoPlayer.vue] - retryTime passed', retryTime);
    //           reject(new Error(`[VideoPlayer.vue] - timedout waiting for API load:
    //             ${this.apiLoad.totalTimeout}ms`));
    //         } else {
    //           debugVerbose && console.log('[VideoPlayer.vue] - retryTime', retryTime);
    //           setTimeout(() => { apiLoadedCheck(); }, this.apiLoad.retryTimeout);
    //         }
    //       }
    //     };
    //     apiLoadedCheck();
    //   });

    //   return apiPromise;
    // },


    // loadApi() {
    //   debugVerbose && console.log('[VideoPlayer.vue] - loadApi');

    //   const tag        = document.createElement('script');
    //   tag.src    = this.apiUrl[this.videos[this.videoActiveId].type];
    //   const [firstTag] = document.getElementsByTagName('script');
    //   firstTag.parentNode.insertBefore(tag, firstTag);

    //   this.apiLoaded()
    //     .then(() => {
    //       debugVerbose && console.log('[VideoPlayer.vue] - loadApi / apiLoaded');
    //       this.apiReady[this.videos[this.videoActiveId].type] = true;
    //       this.playVideo(this.videos[this.videoActiveId]);
    //     })
    //     .catch((reason) => {
    //       debugVerbose && console.log(`[VideoPlayer.vue] - Rejected promise handler: ${reason}`,
    //         this.modalPlayerContainer);
    //       this.showMessage(`Video API error catched.<br>${reason}`);
    //     });
    //   debugVerbose && console.log('[VideoPlayer.vue] - loadApi2');
    // },
  },
};

</script>
<style scoped lang="scss"></style>
