<template>
  <form class="mapForm__form rs_preserve" @submit.prevent="submit">
    <fieldset>
      <h3 id="title" class="mapForm__heading heading__h3 heading--green">
        Contact form
      </h3>
      <ul v-if="submitStatus !== 'OK'" class="mapForm__fields">
        <li class="mapForm__fieldWrapper">
          <label
            class="input__label input__container"
            for="name"
          >
            <input
              id="name"
              v-model.trim="$v.name.$model"
              :class="{
                'input--error': $v.name.$error,
                'input--success': !$v.name.$invalid,
                'input--fill': name
              }"
              type="text"
              name="name"
            >
            <span class="input__placeholder">{{ labels[lang].name }}</span>
            <p v-if="!$v.name.required" class="error">{{ texts[lang].required }}</p>
            <p v-if="!$v.name.minLength" class="error">{{ texts[lang].lengthName }}</p>
            <p v-if="!$v.name.maxLength" class="error">{{ texts[lang].lengthName }}</p>
          </label>
        </li>
        <li class="mapForm__fieldWrapper">
          <label
            class="input__label input__container"
            for="e-mail"
          >
            <input
              id="e-mail"
              v-model.trim="$v.email.$model"
              :class="{
                'input--error': $v.email.$error,
                'input--success': !$v.email.$invalid,
                'input--fill': email
              }"
              type="text"
              name="e-mail"
            >
            <span class="input__placeholder">{{ labels[lang].email }}</span>
            <p v-if="!$v.email.required" class="error">{{ texts[lang].required }}</p>
            <p v-if="!$v.email.email" class="error">{{ texts[lang].email }}</p>
          </label>
        </li>
        <li class="mapForm__fieldWrapper">
          <label
            class="input__container input__label"
            for="phone-number"
          >
            <div
              class="input__wrapper"
              :class="{ 
                'input--error': $v.phone.$error,
                'input--success': !$v.phone.$invalid,
                'input--focus': phone.isFocus || phone.number
              }"
            >
              <input
                id="phoneCode"
                v-model="phone.dialCode"
                name="phoneCode"
                type="hidden"
              >

              <VueTelInput
                :placeholder="lang === 'arabic' ? 'رقم الهاتف' : 'Phone number'"
                :valid-characters-only="true"
                :autocomplete="'off'"
                :default-country="'SA'"
                :ignored-countries="['IL']"
                :value="phone.number"
                :input-id="'phoneInput'"
                name="phone"
                @validate="(value) => validateField('phone', value)"
                @input="(value) => updatePhone('phone', value)"
                @country-changed="(value) => updatePhone('phone', value)"
                @focus="() => phone.isFocus = true"
                @blur="() => phone.isFocus = false"
              />
            </div>
            
            <span class="input__placeholder">{{ labels[lang].phone }}</span>
            <p v-if="!$v.phone.number.required" class="error">{{ texts[lang].required }}</p>
            <p v-if="!$v.phone.number.numeric" class="error">{{ texts[lang].phone }}</p>
            <p v-if="!$v.phone.number.minLength" class="error">{{ texts[lang].minlengthPhone }}</p>
            <p v-if="!$v.phone.number.maxLength" class="error">{{ texts[lang].maxlengthPhone }}</p>
          </label>
        </li>
        <li class="mapForm__fieldWrapper">
          <label
            class="input__label input__container"
            for="mobile-number"
          >
            <div
              class="input__wrapper"
              :class="{ 
                'input--error': $v.mobile.$error,
                'input--success': !$v.mobile.$invalid,
                'input--focus': mobile.isFocus || mobile.number
              }"
            >
              <input
                id="mobileCode"
                v-model="mobile.dialCode"
                name="mobileCode"
                type="hidden"
              >

              <VueTelInput
                :placeholder="lang === 'arabic' ? 'رقم الهاتف' : 'Mobile number'"
                :valid-characters-only="true"
                :autocomplete="'off'"
                :default-country="'SA'"
                :ignored-countries="['IL']"
                :value="mobile.number"
                :class="mobile.isFocus && 'input__focus'"
                :input-id="'mobileInput'"
                name="mobile"
                @validate="(value) => validateField('mobile', value)"
                @input="(value) => updatePhone('mobile', value)"
                @country-changed="(value) => updatePhone('mobile', value)"
                @focus="() => mobile.isFocus = true"
                @blur="() => mobile.isFocus = false"
              />
            </div>
      
            <span class="input__placeholder">{{ labels[lang].mobile }}</span>
            <p v-if="!$v.mobile.number.required" class="error">{{ texts[lang].required }}</p>
            <p v-if="!$v.mobile.number.numeric" class="error">{{ texts[lang].mobile }}</p>
            <p v-if="!$v.mobile.number.minLength" class="error">{{ texts[lang].minlengthMobile }}</p>
            <p v-if="!$v.mobile.number.maxLength" class="error">{{ texts[lang].maxlengthMobile }}</p>
            <p v-if="!$v.mobile.number.saudiValidation" class="error">{{ texts[lang].saudiCode }}</p>
          </label>
        </li>
        <li class="mapForm__fieldWrapper">
          <label
            class="input__label input__container"
            for="company"
          >
            <input
              id="company"
              v-model.trim="$v.company.$model"
              :class="{
                'input--error': $v.company.$error,
                'input--success': !$v.company.$invalid,
                'input--fill': company
              }"
              type="text"
              name="company"
            >
            <span class="input__placeholder">{{ labels[lang].company }}</span>
            <p v-if="!$v.company.required" class="error">{{ texts[lang].required }}</p>
            <p v-if="!$v.company.minLength" class="error">{{ texts[lang].lengthCompany }}</p>
            <p v-if="!$v.company.maxLength" class="error">{{ texts[lang].lengthCompany }}</p>
          </label>
        </li>
        <li v-if="extended" class="mapForm__fieldWrapper">
          <div class="radio__group radio__group--circle">
            <label
              v-for="(element, index) in radio[lang]"
              :key="index"
              :for="'radio-' + index"
              class="radio__container"
            >
              <input
                :id="'radio-' + index"
                v-model.trim="radioEl"
                :value="element"
                class="radio__input"
                type="radio"
                name="radio"
              >
              <span class="radio__checkmarkWrapper"></span>
              <span class="radio__label">{{ element }}</span>
            </label>
          </div>
        </li>
        <h4 class="mapForm__subHeading heading__h">
          {{ labels[lang].messagePurpose }}
        </h4>
        <li class="mapForm__fieldWrapper">
          <div class="radio">
            <div class="radio__group">
              <label
                v-for="(element, index) in topics[lang]"
                :key="index"
                :for="index"
                class="radio__container"
              >
                <input
                  :id="index"
                  v-model.trim="topicEl"
                  :value="element"
                  class="radio__input"
                  type="radio"
                  name="messagePurpose"
                >
                <span class="radio__checkmarkWrapper"></span>
                <span class="radio__label">{{ element }}</span>
              </label>
            </div>
          </div>
        </li>
        <li v-if="extended" class="mapForm__fieldWrapper">
          <div class="checkbox__group">
            <label
              v-for="(element, index) in checkboxes[lang]"
              :key="index"
              :for="'checkbox-' + index"
              class="checkbox__container"
            >
              <input
                :id="'checkbox-' + index"
                v-model="checkEl"
                :value="element"
                class="checkbox__input"
                type="checkbox"
                name="checkbox"
              >
              <span class="checkbox__checkmarkWrapper"></span>
              <span class="checkbox__label">{{ element }}</span>
            </label>
          </div>
        </li>
        <li v-if="extended" class="mapForm__fieldWrapper">
          <label class="input__label input__container">
            <span v-if="!checkFile" class="input__placeholder">{{ file[lang].title }}</span>
            <input type="text" :value="checkFile ? fileItem.name : ''">

            <input type="file" @change="updateFile">
          </label>
        </li>
        <h4 class="mapForm__subHeading heading__h">
          {{ labels[lang].reason }}
        </h4>
        <li class="mapForm__fieldWrapper">
          <label class="input__label input__container" for="reason">
            <select
              v-model.trim="$v.reason.$model"
              class="mapForm__options select-css"
              :class="{
                'input--error': $v.reason.$error,
                'input--success': !$v.reason.$invalid,
                'input--fill': reason
              }"
            >
              <option disabled value="" class="mapForm__option">Please select one</option>
              <option
                v-for="(option, index) in reasons[lang]"
                :key="index"
                :value="option"
                class="mapForm__option"
              >{{ option }}</option
              >
            </select>
            <p v-if="!$v.reason.required" class="error">{{ texts[lang].required }}</p>
          </label>
        </li>
        <li class="mapForm__fieldWrapper">
          <label
            class="input__label input__container"
            for="subject"
          >
            <input
              id="subject"
              v-model.trim="$v.subject.$model"
              :class="{
                'input--error': $v.subject.$error,
                'input--success': !$v.subject.$invalid,
                'input--fill': subject
              }"
              type="text"
              name="subject"
            >
            <span class="input__placeholder">{{ labels[lang].subject }}</span>
            <p v-if="!$v.subject.required" class="error">{{ texts[lang].required }}</p>
            <p v-if="!$v.subject.minLength" class="error">{{ texts[lang].lengthSubject }}</p>
            <p v-if="!$v.subject.maxLength" class="error">{{ texts[lang].lengthSubject }}</p>

          </label>
        </li>
        <li class="mapForm__fieldWrapper">
          <label
            class="input__label input__container"
            for="your-message"
          >
            <textarea
              id="your-message"
              v-model.trim="$v.message.$model"
              :class="{
                'input--error': $v.message.$error,
                'input--success': !$v.message.$invalid,
                'input--fill': message
              }"
              name="your-message"
            ></textarea>
            <span class="input__placeholder">{{ labels[lang].message }}</span>
            <p v-if="!$v.message.required" class="error">{{ texts[lang].required }}</p>
            <p v-if="!$v.message.minLength" class="error">{{ texts[lang].lengthMessage }}</p>
            <p v-if="!$v.message.maxLength" class="error">{{ texts[lang].lengthMessage }}</p>

          </label>
        </li>
        <li class="mapForm__fieldWrapper">
          <label
            class="input__label input__container"
            for="user-token"
          >
            <input
              id="user-token"
              v-model.trim="captchaToken"
              name="user-token"
              type="hidden"
            >
          </label>
        </li>
        <li class="mapForm__fieldWrapper">
          <button class="button mapForm__submit" type="submit">
            {{ labels[lang].submit }}
          </button>
        </li>
      </ul>
      <div v-if="submitStatus === 'OK'" class="mapForm__submitted">
        <div class="mapForm__submittedWrapper">
          <p class="mapForm__submittedInfo">
            {{ success[lang].title }}
          </p>
          <p class="mapForm__submittedInfo">
            {{ success[lang].subtitle }}
          </p>
        </div>
      </div>
    </fieldset>
  </form>
</template>

<script>
import Vue from 'vue';
import { required, minLength, maxLength, email, numeric, helpers  } from 'vuelidate/lib/validators';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import { VueTelInput } from 'vue-tel-input';
import anime from 'animejs/lib/anime.es';

const validateIf = (prop, validator) => helpers.withParams({ type: 'validatedIf', prop }, function(value, parentVm) {
    return helpers.ref(prop, this, parentVm) ? validator(value) : true
  })

export default {
  components: {
    VueTelInput,
  },
  props: {
    lang: {
      type: String,
      default: null
    },
    extended: {
      type: Boolean,
      default: false
    },
    texts: {
      type: Object,
      default: null
    },
    labels: {
      type: Object,
      default: null
    },
    legend: {
      type: Object,
      default: null
    },
    reasons: {
      type: Object,
      default: null
    },
    success: {
      type: Object,
      default: null
    },
    topics: {
      type: Object,
      default: null
    },
    checkboxes: {
      type: Object,
      default: null
    },
    radio: {
      type: Object,
      default: null
    },
    file: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      name: '',
      email: '',
      phone: {
        dialCode: '',
        code: '',
        number: null,
        isSaudi: false,
        firstTime: true,
        isFocus: false,
      },
      mobile: {
        dialCode: '',
        code: '',
        number: null,
        isSaudi: false,
        firstTime: true,
        isFocus: false,
      },
      company: '',
      reason: '',
      topicEl: Object.values(this.topics[this.lang])[0],
      radioEl: this.radio ? Object.values(this.radio[this.lang])[0] : '',
      checkEl: [],
      fileItem: {},
      subject: '',
      message: '',
      captchaToken: '',
      submitStatus: null
    };
  },
  validations: {
    name: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(80)
    },
    email: {
      required,
      email
    },
    phone: {
      number: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
    },
    mobile: {
      number: {
        required,
        numeric,
        minLength: minLength(9),
        maxLength: maxLength(10),
        saudiValidation: validateIf('isSaudi', (value) => (value && value.match(/^5/) ? true : false))
      },
    },
    company: {
      required,
      minLength: minLength(4),
      maxLength: maxLength(80)
    },
    topicEl: {
      required
    },
    reason: {
      required
    },
    subject: {
      required,
      minLength: minLength(10),
      maxLength: maxLength(50)
    },
    message: {
      required,
      minLength: minLength(30),
      maxLength: maxLength(256)
    }
  },
  computed: {
    checkFile() {
      return this.fileItem instanceof File ? true : false;
    },
  },
  mounted() {
    const { dir } = document;

    Vue.use(
      VueReCaptcha,
      {
        siteKey: '6Ldi478ZAAAAAG83G5zt-KxBEipzrHSqaxrL7Kde',
        loaderOptions: {
          explicitRenderParameters: {
            badge: dir === 'rtl' ? 'bottomleft' : 'bottomright',
          },
        },
      }
    );
  },
  methods: {
    validateField(type, { possible, number }) {
      if (
        !possible
        && number.significant !== undefined
      ) return false;

      this[type].number = number.significant;
    },
    updatePhone(type, value) {
      if (value instanceof Object) {
        this[type].dialCode = value.dialCode;
        this[type].code     = value.iso2;
        this[type].isSaudi  = value.iso2 === 'SA';
      } else {
        this[type].number = value.replace(/\s+/g, '');
      }

      !this[type].firstTime
        ? this.$v[type].$touch()
        : this[type].firstTime = false;
    },
    updateFile({ target }) {
      this.fileItem = target.files[0];
    },
    scrollTo(hash) {
      const target   = document.querySelector(hash);
      if (!target) return false;

      const bodyRect = document.body.getBoundingClientRect();
      const elemRect = target.getBoundingClientRect();
      const offset   = (elemRect.top - bodyRect.top) - 250;

      const scrollElement = window.document.scrollingElement
        || window.document.documentElement;

      anime({
        targets: scrollElement,
        scrollTop: offset,
        duration: 500,
        easing: 'easeInOutQuad',
      });
    },
    submit() {
      this.$v.$touch();

      if (this.$v.$error) {
        this.submitStatus = "ERROR";
      } else {
        this.submitStatus = "PENDING";

        this.$recaptcha('login').then((token) => {
          this.captchaToken = token;

          setTimeout(() => {
            this.scrollTo('#title');
            this.submitStatus = "OK";
          }, 500)
        });
      }
    }
  }
};
</script>
