export default class ModalPhotoGallery {

  constructor(debug = false)
  {
    this.debug = debug;
    this.debug && console.log('ModalPhotoGallery init');

    if (this.setVars()) {
      this.debug && console.log('ModalPhotoGallery vars init done');
      this.setEvents();
    }
  }

  setVars()
  {
    this.modalTargetAttr = 'data-modal-photo-gallery-target';
    this.itemsAttr       = 'data-modal-photo-gallery';

    this.modalPhotoGalleryArr = document.querySelectorAll('.jsModalPhotoGallery');
    if (!this.modalPhotoGalleryArr.length) return false;

    this.modalTarget = document.querySelector('['+this.modalTargetAttr+']');
    if (!this.modalTarget) return false;

    return true;
  }

  setEvents()
  {
    this.initOnClickEvents();
    this.initCardSliderEvents();
  }

  initOnClickEvents(reboot = false)
  {
    const elems = reboot
      ? document.querySelectorAll('.jsModalPhotoGallery')
      : this.modalPhotoGalleryArr;

    this.debug && console.log('[ModalPhotoGallery] elems', elems);

    elems.forEach((itemEl) => {
      itemEl.addEvent('click', (e) => {
        e.preventDefault();
        this.onClick(e);
      });
    });
  }

  initCardSliderEvents()
  {
    window.addEventListener('CardSlider__SlideChangeStart', (e) => {
      this.debug && console.log('[ModalPhotoGallery] reattaching events Start', e.detail);
    });

    window.addEventListener('CardSlider__SlideChangeEnd', (e) => {
      this.debug && console.log('[ModalPhotoGallery] reattaching events End', e.detail);
      this.initOnClickEvents(true);
    });
  }

  onClick(e)
  {
    this.debug && console.log('ModalPhotoGallery click', e.target, e.target.getAttribute(this.itemsAttr));

    if (e.target.getAttribute(this.itemsAttr) && e.target.getAttribute(this.itemsAttr).length) {
      this.showModal();
      this.initCardSlider(e);
    }
  }

  showModal()
  {
    this.modalTarget.addClass('active');
    document.body.addClass('modal');
  }

  initCardSlider(e)
  {
    if (e.target.dataset && e.target.getAttribute(this.itemsAttr)) {
      this.debug && console.log('ModalPhotoGallery custom event sent');
      const event = new CustomEvent('galleryModalShow', { detail: e.target.getAttribute(this.itemsAttr) });
      window.dispatchEvent(event);
    }
  }
}

