<template>
  <form class="contactForm__form rs_preserve" @submit.prevent="submit">
    <fieldset>
      <legend class="contactForm__legend">
        <span class="contactForm__heading heading__h3 heading--green">{{ legend[lang].title }}</span>
        <span class="contactForm__subHeading">{{ legend[lang].subtitle }}</span>
      </legend>
      <ul v-if="submitStatus !== 'OK'" class="contactForm__fields">
        <li class="contactForm__fieldWrapper">
          <label class="input__label input__container" for="name">
            <input
              id="name"
              v-model.trim="$v.name.$model"
              :class="{ 'input--error': $v.name.$error, 'input--success' : !$v.name.$invalid }"
              type="text"
              name="name"
              aria-label="required"
              required
            >
            <span class="input__placeholder">{{ labels[lang].name }}</span>
            <p v-if="!$v.name.required" class="error">{{ texts[lang].required }}</p>
            <p v-if="!$v.name.minLength" class="error">{{ texts[lang].lengthName }}</p>
            <p v-if="!$v.name.checkChar" class="error">{{ texts[lang].checkLanguage }}</p>
          </label>
        </li>
        <li class="contactForm__fieldWrapper">
          <label class="input__label input__container" for="e-mail">
            <input
              id="e-mail"
              v-model.trim="$v.email.$model"
              :class="{ 'input--error': $v.email.$error, 'input--success' : !$v.email.$invalid }"
              type="text"
              name="e-mail"
              aria-label="required"
              required
            >
            <span class="input__placeholder">{{ labels[lang].email }}</span>
            <p v-if="!$v.email.required" class="error">{{ texts[lang].required }}</p>
            <p v-if="!$v.email.email" class="error">{{ texts[lang].email }}</p>
          </label>
        </li>
        <li class="contactForm__fieldWrapper">
          <label class="input__label input__container" for="subject">
            <input
              id="subject"
              v-model.trim="$v.subject.$model"
              :class="{ 'input--error': $v.subject.$error, 'input--success' : !$v.subject.$invalid }"
              type="text"
              name="subject"
              aria-label="required"
              required
            >
            <span class="input__placeholder">{{ labels[lang].subject }}</span>
            <p v-if="!$v.subject.required" class="error">{{ texts[lang].required }}</p>
            <p v-if="!$v.subject.minLength" class="error">{{ texts[lang].lengthSubject }}</p>
            <p v-if="!$v.subject.checkChar" class="error">{{ texts[lang].checkLanguage }}</p>
          </label>
        </li>
        <li class="contactForm__fieldWrapper">
          <label class="input__label input__container" for="your-message">
            <textarea
              id="your-message"
              v-model.trim="$v.message.$model"
              :class="{ 'input--error': $v.message.$error, 'input--success' : !$v.message.$invalid }"
              name="your-message"
              aria-label="required"
              required
            ></textarea>
            <span class="input__placeholder">{{ labels[lang].message }}</span>
            <p v-if="!$v.message.required" class="error">{{ texts[lang].required }}</p>
            <p v-if="!$v.message.minLength" class="error">{{ texts[lang].lengthMessage }}</p>
            <p v-if="!$v.message.checkChar" class="error">{{ texts[lang].checkLanguage }}</p>

          </label>
        </li>
        <li class="contactForm__fieldWrapper">
          <button class="button button--shadow contactForm__submit" type="submit">
            {{ labels[lang].submit }}
          </button>
        </li>
      </ul>
      <div v-if="submitStatus === 'OK'" class="contactForm__submitted">
        <p class="contactForm__submittedInfo">
          {{ success[lang].title }}
        </p>
        <p class="contactForm__submittedInfo">
          {{ success[lang].subtitle }}
        </p>
      </div>
    </fieldset>
  </form>
</template>

<script>
  import { required, minLength, email } from 'vuelidate/lib/validators'

  import checkChar from "../validateLanguage";

  export default {
    props: {
      lang: {
        type: String,
        default: null,
      },
      texts: {
        type: Object,
        default: null,
      },
      labels: {
        type: Object,
        default:null,
      },
      legend: {
        type: Object,
        default:null,
      },
      success: {
        type: Object,
        default:null,
      }
    },
    data() {
      return {
        name:            '',
        email:           '',
        subject:         '',
        message:         '',
        submitStatus:    null,
      }
    },
    validations: {
      name: {
        checkChar,
        required,
        minLength: minLength(2)
      },
      email: {
        required,
        email
      },
      subject: {
        checkChar,
        required,
        minLength: minLength(4)
      },
      message: {
        checkChar,
        required,
        minLength: minLength(30)
      },
    },
    methods: {
      submit() {
        this.$v.$touch()
        if (this.$v.$invalid) {
          this.submitStatus = 'ERROR'
        } else {
          // do your submit logic here
          this.submitStatus = 'PENDING'
          setTimeout(() => {
            this.submitStatus = 'OK'
          }, 500)
        }
      },
    }
  }

</script>
