<template>
  <div ref="sliderWrapper" :style="'minHeight: ' + height + 'px'">
    <div ref="slide" class="homeSlider__slide">
      <div class="homeSlider__photos">
        <transition
          :name="transition"
          mode="out-in"
        >
          <img
            v-for="(photo, idx) in photos"
            v-if="slide == idx"
            ref="photo"
            :key="idx"
            :src="photo.img[textDirection]"
            :alt="photo.name"
            class="homeSlider__photo"
          >
        </transition>
      </div>
      <div class="homeSlider__texts">
        <transition
          :name="transition"
          mode="out-in"
        >
          <div v-for="(photo, idx) in photos"
               v-if="slide == idx"
               :key="idx"
               class="homeSlider__textWrapper"
          >
            <h2 class="homeSlider__header heading__h2 heading--green">
              {{ photo.heading }}
            </h2>
            <div class="homeSlider__text">
              {{ photo.text }}
            </div>
            <a class="homeSlider__button button button--shadow" :href=" photo.buttonLink ">
              {{ photo.buttonText }}
            </a>
          </div>
        </transition>
      </div>
    </div>

    <div class="homeSlider__galleryButtons">
      <button
        class="button button--dot button--arrowLeft"
        :class="{ disabled: buttonDisabledLeft }"
        @click.prevent="click('left')"
      ></button>
      <button
        class="button button--dot button--arrowRight"
        :class="{ disabled: buttonDisabledRight }"
        @click.prevent="click('right')"
      ></button>
    </div>

    <ul class="homeSlider__galleryIndicators">
      <li v-for="(photo, idx) in photos" :key="idx">
        <button
          class="button button--dotMini"
          :class="slide == idx ? 'active' : ''"
          @click.prevent="indicatorClick(idx)"
        ></button>
      </li>
    </ul>
  </div>
</template>


<script>

  // eslint-disable-next-line no-unused-vars
  import { debug, debugVerbose } from '../../debug';

  export default {
    props:
    {
      photos: {
        type: Array,
        default: null,
      },
      // should last slide switch to the first one
      isWrapped: {
        type: Boolean,
        default: false,
      },
      // should slider autoplay
      isPauseableOnMouse: {
        type: Boolean,
        default: true,
      },
      // should slider autoplay
      isAutoplayed: {
        type: Boolean,
        default: false,
      },
      // should touch/drag be activated
      isDraggable: {
        type: Boolean,
        default: false,
      },
      // autoplay timeout [ms]
      autoplayTimeout: {
        type: Number,
        default: 5000,
      },
    },

    data() {
      return {
        slide:           0,
        height:          0,
        transition:      'slide-fade-left',
        autoplayTimerId: null,
        dragging:        false,
        draggingDelta:   0,
        draggingSpeed:   0.5,
        transitionTime:  500,
      }
    },

    computed: {
      textDirection: function () {
        return getComputedStyle(document.querySelector('html')).direction;
      },
      buttonDisabledLeft: function () {
        if (this.isWrapped) return;
        return this.slide === 0;
      },
      buttonDisabledRight: function () {
        if (this.isWrapped) return;
        return this.slide === this.photos.length - 1;
      },
    },

    created()
    {
      window.addEventListener('load', this.setSliderHeight);
      this.preloadImages();
    },

    mounted()
    {
      debugVerbose && console.log('simple-slider mounted', this.photos.length, this.isAutoplayed);

      this.isAutoplayed && this.initAutoplay();
      this.isDraggable && this.initDraggable();
    },

    methods: {
      preloadImages()
      {
        if (!this.photos.length) return;
        this.photos.forEach((photo) => {
          const img = new Image();
          img.src = photo.img;
        });
      },

      setSliderHeight()
      {
        let maxHeight = 0;
        if (!this.photos.length) return;

        this.photos.forEach((photo) => {
          const img = new Image();
          img.src = photo.img[this.textDirection];
          maxHeight = Math.max(maxHeight, img.height);
        });

        this.height = this.$refs.photo[0].height;
        // this.height = maxHeight;
      },

      transitionDir(opposite = false)
      {
        if (opposite) {
          return this.textDirection === 'ltr' ? 'slide-fade-right' : 'slide-fade-left';
        } else {
          return this.textDirection === 'ltr' ? 'slide-fade-left' : 'slide-fade-right';
        }
      },

      initDraggable()
      {
        this.dragging      = false;
        this.draggingDelta = 0;

        let initX;
        let clientX;
        let mousePressX;
        let draggableSlide = this.$refs['slide'];

        const dragSlideHandler = (event) => {
          debugVerbose && console.log('[HomeSlider] DOWN event / dragging / type', this.dragging, event.type);
          if (!this.dragging) return;

          clientX = event.touches ? event.touches[0].clientX : event.clientX;
          this.delta = clientX - mousePressX;
          draggableSlide.style.transform = `translateX(${initX + (this.delta * this.draggingSpeed)}px)`;
        };

        ['mousedown', 'touchstart'].forEach((eventDown) => {
          draggableSlide.addEventListener(eventDown, (event) => {
            debugVerbose && console.log('[HomeSlider] add DOWN event', this.dragging, event.type);

            if (this.notDraggableElement(event.target.tagName)) return;

            this.isAutoplayed && this.stopAutoplay();
            this.dragging = true;
            initX         = event.currentTarget.offsetLeft;
            mousePressX   = event.touches ? event.touches[0].clientX : event.clientX;

            ['mousemove', 'touchmove'].forEach((eventMove) => {
              debugVerbose && console.log('[HomeSlider] add MOVE event', this.dragging);
              window.addEventListener(eventMove, dragSlideHandler, false);
            });
          }, false);
        });

        ['mouseup', 'touchend'].forEach((eventUp) => {
          window.addEventListener(eventUp, () => {
            debugVerbose && console.log('[HomeSlider] add UP event', this.dragging);

            ['mousemove', 'touchmove'].forEach((eventMove) => {
              debugVerbose && console.log('[HomeSlider] remove MOVE event remove');
              window.removeEventListener(eventMove, dragSlideHandler, false);

              this.dragging = false;
              this.isAutoplayed && this.initAutoplay();

              if (typeof this.delta === 'undefined' || Math.abs(this.delta) < 10) return;

              if (this.textDirection === 'ltr') {
                (this.delta > 0) ? this.click("left") : this.click("right");
              } else {
                (this.delta > 0) ? this.click("right") : this.click("left");
              }
              this.delta = 0;

              setTimeout(() => {
                draggableSlide.style.transform = `translateX(0px)`;
              }, this.transitionTime);
            });
          });
        });
      },

      initAutoplay()
      {
        if (this.autoplayTimerId) return;

        if (this.isPauseableOnMouse && this.$refs["sliderWrapper"]) {
          this.$refs["sliderWrapper"].addEvent('mouseenter', () => {
            this.stopAutoplay();
          });

          this.$refs["sliderWrapper"].addEvent('mouseleave', () => {
            this.startAutoplay();
          });
        }

        this.startAutoplay();
      },

      stopAutoplay()
      {
        this.autoplayTimerId && clearInterval(this.autoplayTimerId);
      },

      startAutoplay()
      {
        this.autoplayTimerId = setInterval(() => {
          this.click('right');
        }, this.autoplayTimeout);
      },

      notDraggableElement(elem)
      {
        return -1 !== ['A', 'BUTTON'].indexOf(elem);
      },

      indicatorClick(idx)
      {
        if (this.slide == idx) return;

        this.transition = this.slide > idx ? this.transitionDir(false) : this.transitionDir(true);
        this.slide = idx;
      },

      click(dir) {
        switch (dir) {
          case 'right':
            this.transition = this.transitionDir(true);
            if (this.slide + 1 !== this.photos.length) {
              this.slide++;
            } else if (this.isWrapped) {
              this.slide = 0;
            }
            break;
          case 'left':
            this.transition = this.transitionDir(false);
            if (this.slide !== 0) {
              this.slide--;
            } else if (this.isWrapped) {
              this.slide = this.photos.length - 1;
            }
            break;
          default:
        }
      }
    }
  };

</script>

<style scoped lang="scss">

  .homeSlider {
    position: relative;
    // margin: 30px;
    transition: height 0s 1s;
    overflow: hidden;
  }

  //   &__slide {
  //     display: flex;
  //     align-items: center;
  //     justify-content: space-between;
  //   }

  //   &__text {
  //     color: #3f4448;
  //     font-size: 20px;
  //     font-weight: 100;
  //     line-height: 30px;
  //   }

  //   &__photos {
  //     position: relative;
  //   }

  //   &__photo {
  //     max-height: 700px;
  //     min-height: 240px;
  //     transition: max-height 0.3s 1s;
  //   }

  //   &__galleryNavigation {
  //     display: flex;
  //     position: absolute;
  //     right: 0;
  //     bottom: 0;
  //   }
  // }

  .slide-fade-left-enter-active,
  .slide-fade-left-leave-active,
  .slide-fade-right-enter-active,
  .slide-fade-right-leave-active {
    transition: 0.5s;
  }

  .slide-fade-left-enter {
    opacity: 0;
    transform: translate3d(-100px, 0, 0);
  }
  .slide-fade-left-leave-to {
    opacity: 0;
    transform: translate3d(100px, 0, 0);
  }


  .slide-fade-right-enter {
    opacity: 0;
    transform: translate3d(100px, 0, 0);
  }
  .slide-fade-right-leave-to {
    opacity: 0;
    transform: translate3d(-100px, 0, 0);
  }

</style>