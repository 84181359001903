export default class MenuSearchForm {

  constructor(debug = false)
  {
    this.debug = debug;
    this.debug && console.log('MenuSearchForm init');

    if (this.setVars()) {
      this.debug && console.log('MenuSearchForm vars init done');
      this.setEvents();
    }
  }

  setVars()
  {
    this.menuSearchForm = document.querySelector('.jsMenuSearchForm');
    if (!this.menuSearchForm) return false;

    this.buttonActivate = this.menuSearchForm.querySelector('[data-menu-search-activate]');
    if (!this.buttonActivate) return false;

    this.pageBackdrop = document.querySelector('[data-modal-backdrop]');
    if (!this.pageBackdrop) return false;

    this.menuBackdrop = document.querySelector('[data-menu-search-backdrop]');
    if (!this.menuBackdrop) return false;

    this.active = false;

    this.ESC = 27;

    return true;
  }


  setEvents()
  {
    this.buttonActivate.addEvent('click.menusearch', (e) => {
      e.preventDefault();
      this.showForm();
    });

    this.pageBackdrop.addEvent('click.menusearch', () => {
      this.active && this.hideForm();
    });

    this.menuBackdrop.addEvent('click.menusearch', (e) => {
      const notClickedOnform = !e.target.type
        || (e.target.type && e.target.type !== 'text' && e.target.type !== 'submit');

      if (this.active && (notClickedOnform)) {
        this.hideForm();
      }
    });

    document.addEvent('keydown.menusearch', e => {
      this.handleKeyEscEvent(e);
    });
  }


  showForm()
  {
    setTimeout(() => {
      this.active = true;
    }, 50);

    this.menuSearchForm.addClass('active');
    this.pageBackdrop.addClass('active');
  }


  hideForm()
  {
    this.active = false;
    this.menuSearchForm.removeClass('active')
    this.pageBackdrop.removeClass('active')
  }

  handleKeyEscEvent(e)
  {
    if (e.keyCode !== this.ESC) return;

    this.hideForm();
  }
}
