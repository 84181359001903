export default class Print
{
  constructor(debug = false)
  {
    this.debug = debug;
    this.debug && console.log('Print init');

    if (this.setVars()) {
      this.debug && console.log('Print vars init done');
      this.setEvents();
    }
  }

  setVars()
  {
    this.printElemsArr = document.querySelectorAll('[data-print]');
    if (!this.printElemsArr.length) return;

    return true;
  }

  setEvents()
  {
    this.printElemsArr.forEach((el) => {
      el.addEvent('click.print', e => {
        e.preventDefault();
        this.printInit();
      });
    });
  }

  printInit()
  {
    this.debug && console.log('print init');
    window.print();
  }
}
