import anime from 'animejs/lib/anime.es';

export default class MenuMobile
{
  constructor()
  {
    if (!this.setVars()) return;
    this.setEvents();
  }

  setVars()
  {
    const mediaMatch = window.matchMedia(`(max-width: 1023px)`);
    if (mediaMatch.matches) {
      this.mobile = true;
    }
    if(!this.mobile) return;

    this.menu = document.querySelector('.topMenu');
    if(!this.menu) return;

    this.submenuToggle = [...this.menu.querySelectorAll('[data-header-link]')];
    if(!this.submenuToggle.length) return;

    return true;
  }

  setEvents()
  {
    this.submenuToggle.forEach(link => {
      link.addEventListener('click', (e) => {
        link.classList.toggle('topMenu__link--rotate');
        this.toggle(e);
      });
    });
  }

  toggle(e) {
    const target = e.currentTarget;
    const child = target.parentNode.querySelector('[data-header-link-child]');
    const level  = target.dataset.headerLink;
    const openMenus = this.menu.querySelectorAll('.topMenu__subMenu.active');
    let childHeight = target.parentNode.querySelector('.topMenu__subMenu').clientHeight;

    if (level === 1 && openMenus && !target.classList.contains('isOpen')) {
      openMenus.forEach(el => {
        el.parentNode.querySelector('[data-header-link]').classList.remove('isOpen');
        anime({
          targets: child,
          opacity: ['1', '0'],
          duration: 400,
          height: [childHeight, 0],
          easing: 'easeInOutCirc',
          complete: () => {
            child.style.height = '';
            el.classList.remove('active');
          }
        });
      });
    }

    if (target.classList.contains('isOpen')) {
      let activeChildMenu = target.parentNode.querySelectorAll('.topMenu__subMenu.active');
      target.classList.remove('isOpen');

      if (activeChildMenu) {
        activeChildMenu.forEach(el => {
          el.parentNode.querySelector('[data-header-link]').classList.remove('isOpen');
          anime({
            targets: child,
            opacity: ['1', '0'],
            duration: 400,
            height: [childHeight, 0],
            easing: 'easeInOutCirc',
            complete: () => {
              child.style.height = '';
              child.style.opacity = '';
              el.classList.remove('active');
            }
          });
        });
      }
    } else {
      anime({
        targets: child,
        opacity: ['0', '1'],
        duration: 400,
        height: [0, childHeight],
        easing: 'easeInOutCirc',
        complete: () => {
          child.style.height = '';
          child.style.opacity = '';
        }
      });
      child.classList.add('active');
      target.classList.add('isOpen');
    }
  }
}
