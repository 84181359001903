export default class Accordion
{
  constructor()
  {
    if (!this.setVars()) return;

    this.setEvents();
  }

  setVars()
  {
    this.accordion = document.querySelector('.accordion');
    if (!this.accordion) return;

    this.items  = [...this.accordion.querySelectorAll('.accordion__content')];

    return true;

  }

  setEvents()
  {
    this.items.forEach( item => {
      item.addEventListener("click", () => {
        let content = item.nextElementSibling;
        content.classList.toggle('accordion__inner--active');
        content.parentElement.classList.toggle('accordion__item--active');
        if (content.style.maxHeight) {
          content.style.maxHeight = null;
        } else {
          content.style.maxHeight = content.scrollHeight + "px";
        }
      })
    })
  }


}
