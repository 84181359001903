// import { throttle } from '../vendors/helpers/throttle';
// import IntersectionObserver from 'intersection-observer-polyfill';

/*

Usage:

    <div class="someClass"
      data-anim-off-observer="animation"
      data-anim-off-observer-once="true"
    >

    data-anim-off-observer      - add this class when visible to start animation
    data-anim-off-observer-once - should class be added only once - to be
                                    used with non looping animatios like:

        .someClass {
          animation: upAndDownMouse 5s ease-in infinite;
        }

*/

export default class AnimOffObserver {

  constructor(debug = false) {
    this.debug = debug;
    this.debug && console.log('AnimOffObserver init');

    if (this.setVars()) this.setEvents();
  }


  setVars()
  {
    // this.elemArr = [...document.querySelectorAll('[data-anim-off-observer]')];
    this.elemsNodeList = document.querySelectorAll('[data-anim-off-observer]');
    if (!this.elemsNodeList.length) return;

    this.DELAY_INIT  = 500;
    this.THRESHOLD_1 = 0.01;

    return true;
  }


  setEvents()
  {
    // window.addEvent('load', () => {
    //   setTimeout(() => {
    this.debug && console.log('[AnimOffObserver] setting up for ', this.elemsNodeList[0])
    setTimeout(() => {
      this.setupObserver();
      this.elemsNodeList.forEach((el) => {
        // if (this.debug) console.log('setting observer to: ', el);
        this.observer.observe(el);
      });
    }, this.DELAY_INIT);
    //   }, 0);
    // });

    this.setupObserver();
  }

  setupObserver()
  {
    const options = {
      // root: document.querySelector('main'),
      rootMargin: '0px',
      threshold: [this.THRESHOLD_1],
      root: null,
    };

    const callback = (entries/* , observer */) => {
      // console.log(entries, observer);

      this.doActionsForOnIntersect(entries.filter(el => el.isIntersecting === true));
      this.doActionsForOffIntersect(entries.filter(el => el.isIntersecting === false));
    };

    this.observer = new IntersectionObserver(callback, options);
  }

  // eslint-disable-next-line class-methods-use-this
  doActionsForOnIntersect(entries)
  {
    entries.forEach((entry) => {
      // console.log('on', entry.target);
      entry.target.addClass(entry.target.getAttribute('data-anim-off-observer'));

      if (entry.target.getAttribute('data-anim-off-observer-once')) {
        // console.log('unobserving', entry.target);
        this.observer.unobserve(entry.target);
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  doActionsForOffIntersect(entries)
  {
    entries.forEach((entry) => {
      // console.log('OFF', entry.target);
      entry.target.removeClass(entry.target.getAttribute('data-anim-off-observer'));
    });
  }
}
