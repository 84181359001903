export default class WcagKeyboardNavig {

  constructor(debug = false) {

    this.debug = debug;

    if (this.debug) console.log('WcagKeyboardNavig init');

    if (this.setVars()) {
      if (this.debug) console.log('WcagKeyboardNavig vars init done');
      this.setEvents();
    }
  }

  setVars()
  {
    this.TAB         = 9;
    this.ESC         = 27;
    this.ENTER       = 13;
    this.ARROW_LEFT  = 37;
    this.ARROW_UP    = 38;
    this.ARROW_RIGHT = 39;
    this.ARROW_DOWN  = 40;

    this.navigType  = 'data-wcag-navig-type';
    this.navigClass = 'wcga';

    this.wcgaKeyDown = false;

    this.appState    = '';
    this.tabNodes    = null;

    this.nodeSelected = {
      menuItem:     0,
      menuSubItem:  0,
      menuSubId:    0,
      menuSubSubId: 0,
    };

    return true;
  }


  setEvents()
  {
    document.addEvent('keydown.wcag', (e) => {
      this.handleKeyDownEvent(e);
    });

    document.body.addEvent('click.wcag', () => {
      this.removeKeyboardNavigationClass();
    });
  }


  handleKeyDownEvent(e)
  {
    this.checkKeyDownAndExecuteAction(e);
    this.updateMenuKeyboardNavigationClass();
  }


  updateMenuKeyboardNavigationClass()
  {
    if (this.wcgaKeyDown) {
      this.addKeyboardNavigationClass();
    }
    else {
      this.removeKeyboardNavigationClass();
    }
  }


  addKeyboardNavigationClass()
  {
    document.body.addClass(this.navigClass);
  }


  removeKeyboardNavigationClass()
  {
    document.body.removeClass(this.navigClass);
  }


  checkKeyDownAndExecuteAction(e)
  {
    this.wcgaKeyDown = true;

    // e.shiftKey true/false
    switch (e.keyCode) {
    case this.TAB:
      this.handleTab(e, e.shiftKey);
      break;
    case this.ESC:
      this.handleEsc(e);
      break;
    case this.ENTER:
      this.handleEnter(e);
      break;
    case this.ARROW_LEFT:
        // this.handleArrowUp(e);
      break;
    case this.ARROW_UP:
      this.handleArrowUp(e);
      break;
    case this.ARROW_RIGHT:
        // this.handleArrowDown(e);
      break;
    case this.ARROW_DOWN:
      this.handleArrowDown(e);
      break;
    default:
      this.wcgaKeyDown = false;
    }
  }


  handleEnter(e)
  {
    const { target } = e;
    const targetType = target.getAttribute(this.navigType);

    (targetType === 'menu') && this.openMenu(target);

    if (targetType === 'submenu') {
      this.openSubMenu(target);
    }
  }


  handleTab(e, reverse = false)
  {
    this.debug && console.log('tab pressed (reverse)', reverse);

    if (this.appState === 'menuOpen') {
      e.preventDefault();
      this.nodeSelected.menuSubId = this.calculateNextMenuSubId(this.nodeSelected.menuSubId, reverse);
      this.tabNodes[this.nodeSelected.menuSubId].querySelector(':scope > a').focus();

      console.log(this.tabNodes.length, this.nodeSelected);
    }

    if (this.appState === 'menuSubOpen') {
      e.preventDefault();
      this.nodeSelected.menuSubSubId = this.calculateNextMenuSubId(this.nodeSelected.menuSubSubId, reverse);
      this.tabNodes[this.nodeSelected.menuSubSubId].querySelector(':scope > a').focus();

      console.log(this.tabNodes.length, this.nodeSelected);
    }
  }

  handleArrowDown(e)
  {
    const { target } = e;
    const targetType = target.getAttribute(this.navigType);

    if (this.appState === 'menuOpen' || this.appState === 'menuSubOpen') {
      this.handleTab(e, false);
    }
    else {
      (targetType === 'menu') && this.openMenu(target);
    }
  }


  handleArrowUp(e)
  {
    if (this.appState === 'menuOpen' || this.appState === 'menuSubOpen') {
      this.handleTab(e, true);
    }
  }


  openMenu(target)
  {
    const selectedMenuItem = target.parentElement;

    this.appState = 'menuOpen';
    this.nodeSelected.menuItem = target.parentElement;
    this.toggleClass(selectedMenuItem, 'active');
    this.tabNodes = selectedMenuItem.querySelectorAll(':scope > ul > li');
    this.tabNodes[this.nodeSelected.menuSubId].querySelector(':scope > a').focus();

    this.debug && console.log('enter subMenu', this.tabNodes);
  }


  openSubMenu(target)
  {
    const selectedMenuItem = target.parentElement;

    this.appState = 'menuSubOpen';
    this.nodeSelected.menuSubItem = target.parentElement;
    this.toggleClass(selectedMenuItem, 'active');
    this.tabNodes = selectedMenuItem.querySelectorAll(':scope > ul > li');
    this.tabNodes[this.nodeSelected.menuSubSubId].querySelector(':scope > a').focus();

    this.debug && console.log('enter subSubMenu', this.tabNodes);
  }


  calculateNextMenuSubId(nodeId, reverse)
  {
    let idToSelect;

    if (reverse) {
      if (nodeId === 0) {
        idToSelect = this.tabNodes.length - 1;
      }
      else {
        idToSelect = (nodeId - 1) % this.tabNodes.length;
      }
    }
    else {
      idToSelect = (nodeId + 1) % this.tabNodes.length;
    }

    return idToSelect;
  }


  handleEsc()
  {
    // console.log('esc pressed', this.nodeSelected.menuItem);

    if (this.appState === 'menuOpen' || this.appState === 'menuSubOpen') {
      if (this.nodeSelected.menuItem) {
        this.nodeSelected.menuItem.querySelector(':scope > a').focus();
        this.nodeSelected.menuItem.removeClass('active');

        this.appState                  = '';
        this.tabNodes                  = null;
        this.nodeSelected.menuSubId    = 0;
        this.nodeSelected.menuSubSubId = 0;
      }
      if (this.nodeSelected.menuSubItem) {
        this.nodeSelected.menuSubItem.removeClass('active');
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  toggleClass(el, className)
  {
    if (el.hasClass(className)) {
      el.removeClass(className);
    }
    else {
      el.addClass(className);
    }
  }
}
