<template>
  <div ref="sliderWrapper">
    <div class="cardSlider__slides">
      <div
        v-if="slidesGrouped.length"
        ref="slide"
        class="cardSlider__slide"
      >
        <transition
          :name="transition"
          mode="out-in"
        >
          <div
            v-for="(slideObj, idx) in slidesGrouped"
            v-if="slide == idx"
            :key="idx"
            class="cardSlider__slideInner"
          >
            <!--
            <slot v-if="responsiveMode == 'mobile'" name="mobile" :slideObj="slideObj"></slot>
            <slot v-if="responsiveMode == 'tablet'" name="tablet" :slideObj="slideObj"></slot>
            -->
            <!-- <slot v-if="responsiveMode == 'desktop'" name="desktop" :slideObj="slideObj"></slot> -->
            <slot v-if="responsiveMode == 'default'" :slideObj="slideObj">
              <a :href="slideObj.link" class="cardSlider__link">
                <div class="cardSlider__img">
                  <img :src="slideObj.img">
                </div>
                <div class="cardSlider__date">{{ slideObj.date }}</div>
                <h2 class="cardSlider__header">{{ slideObj.title }}</h2>
                <div class="cardSlider__text">{{ slideObj.text }}</div>
              </a>
            </slot>
          </div>
        </transition>
      </div>
      <div
        v-else
        class="cardSlider__slide cardSlider__slide--empty"
      >
        - no images provided -
      </div>
    </div>
    <div class="cardSlider__controls">
      <div
        v-if="closeVisible"
        class="cardSlider__galleryClose"
        :class="styleGalleryClose"
      >
        <button
          v-if="closeVisible"
          class="button button--close button--green button--dot"
          @click.prevent="click('close')"
        ></button>
      </div>
      <div
        v-if="hasNavButtons"
        class="cardSlider__galleryButtons"
        :class="styleGalleryButtons"
      >
        <button
          class="button button--arrowLeft"
          :class="{ disabled: buttonDisabledLeft, 'button--dotSmall': hasSmallButtons, 'button--dot': !hasSmallButtons }"
          @click.prevent="click('left')"
        ></button>
        <button
          class="button button--arrowRight"
          :class="{ disabled: buttonDisabledRight, 'button--dotSmall': hasSmallButtons, 'button--dot': !hasSmallButtons }"
          @click.prevent="click('right')"
        ></button>
      </div>

      <ul
        v-if="hasNavIndicators"
        class="cardSlider__galleryIndicators"
        :class="styleGalleryIndicators"
      >
        <li v-for="(photo, idx) in slidesGrouped" :key="idx">
          <button
            class="button button--dotMini"
            :class="slide == idx ? 'active' : ''"
            @click.prevent="indicatorClick(idx)"
          ></button>
        </li>
      </ul>
    </div>
  </div>
</template>


<script>

  // eslint-disable-next-line no-unused-vars
import { debugVerbose, debug } from '../../debug';

export default {
  props:
    {
      slides: {
        type: Array,
        default: null,
      },
      // items per slide in defines resolutions like so:
      // '[{"desktop":3},{"tablet":2},{"mobile":1}]'
      // could be set to null for no responsive behaviour - when
      // we have onlu one item per slide
      responsive: {
        type: Object,
        default: null,
      },
      // should accept 'galleryModalShow' event for loading images
      isPortal: {
        type: Boolean,
        default: false,
      },
      // should last slide switch to the first one
      isWrapped: {
        type: Boolean,
        default: false,
      },
      // should slider autoplay
      isPauseableOnMouse: {
        type: Boolean,
        default: true,
      },
      // should slider autoplay
      isAutoplayed: {
        type: Boolean,
        default: false,
      },
      // autoplay timeout [ms]
      autoplayTimeout: {
        type: Number,
        default: 5000,
      },
      // should touch/drag be activated
      isDraggable: {
        type: Boolean,
        default: false,
      },
      // are nav buttons small?
      hasSmallButtons: {
        type: Boolean,
        default: true,
      },
      // has nav buttons?
      hasNavButtons: {
        type: Boolean,
        default: true,
      },
      // has nav buttons?
      hasNavIndicators: {
        type: Boolean,
        default: true,
      },
      // gallery buttons additional style
      styleGalleryClose: {
        type: String,
        default: '',
      },
      // gallery buttons additional style
      styleGalleryButtons: {
        type: String,
        default: '',
      },
      // gallery buttons additional style
      styleGalleryIndicators: {
        type: String,
        default: '',
      },
    },
  data() {
    return {
      slide:              0,
      transition:         'slide-fade-left',
      perSlot:            1,
      closeVisible:       false,
      slidesExternal:     [],
      responsiveMode:     'default',
      modalContainerAttr: 'data-modal-photo-gallery-target',
      modalContainer:     null,
      autoplayTimerId:    null,
      dragging:           false,
      draggingDelta:      0,
      draggingSpeed:      0.3,
      transitionTime:     500,
    };
  },
  computed: {
    slidesGrouped: function () {
      const grouped = [];
      if (!this.slides.length && !this.slidesExternal.length) return [];

      (this.slidesExternal.length ? this.slidesExternal : this.slides).forEach((el, idx) => {
        const slotNumber = Math.floor(idx / this.perSlot);
        if (!grouped[slotNumber]) {
          grouped.push([]);
        }
        grouped[slotNumber].push(el);
      });

      debugVerbose && console.log('grupped', grouped);
      return grouped;
    },

    textDirection: function () {
      return getComputedStyle(document.querySelector('html')).direction;
    },

    buttonDisabledLeft: function () {
      if (!this.slides || this.slidesGrouped.length === 1) return true;
      if (this.isWrapped) return;
      return this.slide === 0;
    },

    buttonDisabledRight: function () {
      if (!this.slides || this.slidesGrouped.length === 1) return true;
      if (this.isWrapped) return;
      return this.slide === this.slidesGrouped.length - 1;
    },
  },
  mounted()
  {
    debugVerbose && console.log('card-slider mounted', this.slides, this.isDraggable);

    this.isAutoplayed && this.initAutoplay();
    this.isDraggable && this.initDraggable();

    this.calcSlidesPerSlot();

    window.addEventListener('galleryModalShow', (e) => {
      this.isPortal && this.loadNewItems(e);
    });
  },
  methods: {
    calcSlidesPerSlot()
    {
      if (!this.responsive || !this.responsive.ranges.length) return;

      const clientWidth = document.body.clientWidth;
      this.perSlot = this.responsive.default;
      this.responsive.ranges.forEach(range => {
        if (clientWidth >= range.minWidth) {
          this.perSlot = Math.max(this.perSlot, range.perSlot);
        }
      });
    },

    loadNewItems(e)
    {
      debugVerbose && console.log('[CardSlider.vue] - loading event action', e.detail);

      if (e.detail) {
        const items = JSON.parse(e.detail);
        this.slidesExternal = [];
        debugVerbose && console.log('items', items);
        if (items.length) {
          items.forEach(el => {
            this.slidesExternal.push(
              {
                title: el.title,
                img: el.img,
              }
            );
          });
        }
        this.closeVisible = true;
      }
    },

    transitionDir(opposite = false)
    {
      if (opposite) {
        return this.textDirection === 'ltr' ? 'slide-fade-right' : 'slide-fade-left';
      } else {
        return this.textDirection === 'ltr' ? 'slide-fade-left' : 'slide-fade-right';
      }
    },

    initDraggable()
    {
      this.dragging      = false;
      this.draggingDelta = 0;

      let initialOffsetX;
      let mouseClickedX;
      let draggableSlide = this.$refs['slide'];
      let timeoutId;

      const dragSlideHandler = (event) => {
        debugVerbose && console.log('[HomeSlider] DOWN event / dragging / type', this.dragging, event.type);

        if (!this.dragging) return;
        this.clearGoToLocation(timeoutId);
        this.translateSlideByDrag(event, mouseClickedX, initialOffsetX, draggableSlide);
      };

      ['mousedown', 'touchstart'].forEach((eventDown) => {
        draggableSlide.addEventListener(eventDown, (event) => {
          debugVerbose && console.log('[HomeSlider] add DOWN event', this.dragging, event.type, event.target);

          timeoutId = this.initGoToLocationAndGetTimeout(event.target.closest('a'));

          this.isAutoplayed && this.stopAutoplay();
          this.dragging  = true;
          initialOffsetX = event.currentTarget.offsetLeft;
          mouseClickedX  = event.touches ? event.touches[0].clientX : event.clientX;

          ['mousemove', 'touchmove'].forEach((eventMove) => {
            debugVerbose && console.log('[HomeSlider] add MOVE event', this.dragging);
            window.addEventListener(eventMove, dragSlideHandler, false);
          });
        }, false);
      });

      ['mouseup', 'touchend'].forEach((eventUp) => {
        window.addEventListener(eventUp, () => {
          debugVerbose && console.log('[HomeSlider] add UP event', this.dragging);

          ['mousemove', 'touchmove'].forEach((eventMove) => {
            debugVerbose && console.log('[HomeSlider] remove MOVE event remove');
            window.removeEventListener(eventMove, dragSlideHandler, false);

            this.dragging = false;
            this.isAutoplayed && this.initAutoplay();

            if (typeof this.delta === 'undefined' || Math.abs(this.delta) < 10) return;

            if (this.textDirection === 'ltr') {
              (this.delta > 0) ? this.click("left") : this.click("right");
            }
            else {
              (this.delta > 0) ? this.click("right") : this.click("left");
            }
            this.delta = 0;

            setTimeout(() => {
              draggableSlide.style.transform = `translateX(0px)`;
            }, this.transitionTime);
          });
        });
      });

      draggableSlide.addEventListener('click', (event) => {
        debugVerbose && console.log('[HomeSlider] CLICK event / dragging', this.dragging, event.target);
        event.preventDefault();
      });
    },

    translateSlideByDrag(event, mouseClickedX, initialOffsetX, draggableSlide)
    {
      const mouseDraggedX = event.touches ? event.touches[0].clientX : event.clientX;
      this.delta = mouseDraggedX - mouseClickedX;

      draggableSlide.style.transform = `translateX(${initialOffsetX + (this.delta * this.draggingSpeed)}px)`;
    },

    clearGoToLocation(timeoutId)
    {
      timeoutId && clearTimeout(timeoutId);
    },

    initGoToLocationAndGetTimeout(elem)
    {
      if (!elem) return;

      const clickedUrl = elem.getAttribute('href');
      const timeoutId = setTimeout(() => {
        document.location.href = clickedUrl;
      }, 100);

      return timeoutId;
    },

    initAutoplay()
    {
      if (this.autoplayTimerId) return;

      if (this.isPauseableOnMouse && this.$refs["sliderWrapper"]) {
        this.$refs["sliderWrapper"].addEvent('mouseenter', () => {
          this.stopAutoplay();
        });

        this.$refs["sliderWrapper"].addEvent('mouseleave', () => {
          this.startAutoplay();
        });
      }

      this.startAutoplay();
    },

    stopAutoplay()
    {
      this.autoplayTimerId && clearInterval(this.autoplayTimerId);
    },

    startAutoplay()
    {
      this.autoplayTimerId = setInterval(() => {
        this.click('right');
      }, this.autoplayTimeout);
    },

    notDraggableElement(elem)
    {
      return !['A', 'BUTTON'].indexOf(elem);
    },

    indicatorClick(idx)
    {
      if (this.slide == idx) return;

      this.transition = this.slide > idx ? this.transitionDir(false) : this.transitionDir(true);
      this.slide = idx;
    },

    click(mode) {
      switch (mode) {
      case 'right':
        this.transition = this.transitionDir(true);
        this.slide = (this.slide + 1 !== this.slidesGrouped.length)
          ? this.slide + 1
          : 0;
        break;
      case 'left':
        this.transition = this.transitionDir(false);
        this.slide = (this.slide !== 0)
          ? this.slide - 1
          : this.slidesGrouped.length - 1;
        break;
      case 'close':
        this.modalContainer = document.querySelector('['+this.modalContainerAttr+']');
        this.modalContainer && this.modalContainer.removeClass('active');
        document.body.removeClass('modal');
        break;
      }

      const eventStart = new CustomEvent('CardSlider__SlideChangeStart', {
        detail: {
          direction: mode,
          isPortal:  this.isPortal,
        }
      });
      window.dispatchEvent(eventStart);

      const eventEnd = new CustomEvent('CardSlider__SlideChangeEnd', {
        detail: {
          direction: mode,
          isPortal:  this.isPortal,
        }
      });
      setTimeout(() => {
        this.$nextTick(() => {
          window.dispatchEvent(eventEnd);
        });
      }, 2 * this.transitionTime);
    }
  }
};

</script>

<style scoped lang="scss">

  .cardSlider {
    position: relative;
    // margin: 30px;
    transition: height 0s 1s;
    // overflow: hidden;
  }

  .controls {
    position: relative;
    // margin: 30px;
    transition: height 0s 1s;
    // overflow: hidden;
  }

  //   &__slide {
  //     display: flex;
  //     align-items: center;
  //     justify-content: space-between;
  //   }

  //   &__text {
  //     color: #3f4448;
  //     font-size: 20px;
  //     font-weight: 100;
  //     line-height: 30px;
  //   }

  //   &__slides {
  //     position: relative;
  //   }

  //   &__photo {
  //     max-height: 700px;
  //     min-height: 240px;
  //     transition: max-height 0.3s 1s;
  //   }

  //   &__galleryNavigation {
  //     display: flex;
  //     position: absolute;
  //     right: 0;
  //     bottom: 0;
  //   }
  // }

  .slide-fade-left-enter-active,
  .slide-fade-left-leave-active,
  .slide-fade-right-enter-active,
  .slide-fade-right-leave-active {
    transition: .5s;
  }

  .slide-fade-left-enter {
    opacity: 0;
    transform: translate3d(-100px, 0, 0);
  }
  .slide-fade-left-leave-to {
    opacity: 0;
    transform: translate3d(100px, 0, 0);
  }


  .slide-fade-right-enter {
    opacity: 0;
    transform: translate3d(100px, 0, 0);
  }
  .slide-fade-right-leave-to {
    opacity: 0;
    transform: translate3d(-100px, 0, 0);
  }

</style>