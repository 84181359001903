// import { CircleSlider } from './CircleSlider';
import Accordion from './Accordion';
import AnimOffObserver from './AnimOffObserver';
import Carousel from './Carousel';
import Contrast from './Contrast';
import FontSize from './FontSize';
import Hamburger from './Hamburger';
import Header from './Header';
import Map from './Map';
import MenuMobile from './MenuMobile';
import MenuSearchForm from './MenuSearchForm';
import ModalPhotoGallery from './ModalPhotoGallery';
import ModalVideoPlayer from './ModalVideoPlayer';
import Print from './Print';
import ProgramsCarousel from './ProgramsCarousel';
import ReusableCopy from './ReusableCopy';
import ScrollTo from './ScrollTo';
import ShowOnScroll from './ShowOnScroll';
import Socials from './Socials';
import WcagKeyboardNavig from './WcagKeyboardNavig';
// import VideoPlayer from './VideoPlayer';
// import PageableStarter from './PageableStarter';

class Core {
  // constructor() {
  //   console.log('running core..');
  // }

  static run(stage) {
    // console.log('running core..', stage);

    window.PhotoGallery = ModalPhotoGallery;
    window.VideoGallery = ModalVideoPlayer;
    window.ScrollFunction = ScrollTo;
    window.ShowOnScroll = ShowOnScroll;
    window.MobileMenu = MenuMobile;
    window.Hamburger = Hamburger;
    window.pageHeader = Header;

    switch (stage) {
      case 'dom':
        new Contrast();
        new FontSize();
        new Hamburger();
        new Header();
        new WcagKeyboardNavig();
        new MenuMobile();
        new MenuSearchForm();
        new Print();
        break;

      case 'page':
        new ReusableCopy();
        break;

      case 'vue':
        new Accordion();
        new AnimOffObserver();
        new Carousel();
        new Map();
        new ModalPhotoGallery();
        new ModalVideoPlayer();
        new ProgramsCarousel();
        new ScrollTo();
        new ShowOnScroll();
        new Socials();
        break;
    }
  }
}

const readyDOM = (fn) => {
  if (document.attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading') {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
};

const readyPage = (fn) => {
  window.addEventListener('load', fn);
};

readyDOM(() => {
  console.log('[_Core.js] (dom) ready');
  Core.run('dom');
});

readyPage(() => {
  console.log('[_Core.js] (page) ready');
  Core.run('page');
});

window.addEventListener('vueMounted', () => {
  console.log('[_Core.js] vue mounted event (vue)');
  Core.run('vue');
});

