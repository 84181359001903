import Swiper from 'swiper/js/swiper';


export default class ProgramsCarousel
{
  constructor()
  {
    if (!this.setVars()) return;
    this.numberOfSlides();
    this.setEvents();
  }

  setVars()
  {
    this.carousel         = document.querySelector('.programs__carousel');
    if (!this.carousel) return;

    this.next             = document.querySelector('.programs__next');
    this.prev             = document.querySelector('.programs__prev');

    this.bp      = {
      largePhone    : 480,
      tablet        : 767,
      largeTablet   : 1023,
      laptop        : 1279,   
    }

    this.swiper = null;

    this.items  = 4;

    return true;
  }

  setEvents()
  {
    window.addEventListener('resize', () => {
      this.numberOfSlides();
    });
    this.buildSlider();

  }


  buildSlider()
  {
    this.swiper = new Swiper(this.carousel, {
      loop: true,
      spaceBetween: 8,
      slidesPerView: this.items,
      navigation: {
        nextEl: this.next,
        prevEl: this.prev,
      },
      autoplay: {
        delay                 : 2000,
        disableOnInteraction  : false,
      },
    });
  }

  regenerateSlider()
  {
    if (this.swiper !== null) {
      this.swiper.destroy(true, false);
    }
    this.buildSlider();
  }

  checkIfRegenrate(numberOfItems)
  {
    if (this.items !== numberOfItems) {
      this.items = numberOfItems;
      this.regenerateSlider();
    }
  }

  numberOfSlides()
  {
    const width = window.innerWidth;

    if (width > this.bp.laptop) {
      this.checkIfRegenrate(4);
    } else if (width <= this.bp.laptop && width > this.bp.tablet ) {
      this.checkIfRegenrate(3);
    } else if (width <= this.bp.tablet && width > this.bp.largePhone ) {
      this.checkIfRegenrate(2);
    } else if (width <= this.bp.largePhone ) {
      this.checkIfRegenrate(1);
    }
  }

}
