export default class ModalVideoPlayer {

  constructor(debug = false)
  {
    this.debug = debug;
    this.debug && console.log('ModalVideoPlayer init');

    if (this.setVars()) {
      this.debug && console.log('ModalVideoPlayer vars init done');
      this.setEvents();
    }
  }

  setVars()
  {
    this.videoUrl = 'data-video-url';

    this.modalVideoPlayerArr = document.querySelectorAll('.jsModalVideoPlayer');
    if (!this.modalVideoPlayerArr.length) return false;

    return true;
  }

  setEvents()
  {
    this.modalVideoPlayerArr.forEach((itemEl) => {
      itemEl.addEvent('click', (e) => {
        e.preventDefault();
        this.onClick(e);
      });
    });
  }

  onClick(e)
  {
    this.debug && console.log('ModalVideoPlayer click', e.target, e.target.getAttribute(this.videoUrl));

    if (e.target.getAttribute(this.videoUrl)) {
      this.initModalVideoPlayer(e);
    }
  }

  initModalVideoPlayer(e)
  {
    if (/* e.target.dataset &&  */e.target.getAttribute(this.videoUrl)) {
      this.debug && console.log('ModalVideoPlayer custom event sent', e.target.getAttribute(this.videoUrl));
      const event = new CustomEvent('videoModalShow', { detail: e.target.getAttribute(this.videoUrl) });
      window.dispatchEvent(event);
    }
  }
}

