// import { ScrollToPlugin } from 'gsap/ScrollToPlugin'; // eslint-disable-line no-unused-vars
// import { CSSPlugin } from "gsap/CSSPlugin";
// import anime from 'animejs/lib/anime.es';


/**
 * Usage:
 *   <a href="#" class="jsScrollTo" data-scrollto-id="" data-scrollto-fps="30" data-scrollto-speed="120" data-scrollto-duration="1500"></a>
 *
 * Params:
 *    - data-scrollto-speed: pixels / animation frame (default 100, has priority over duration parameter)
 *    - data-scrollto-duration: in miniseconds,  independent of page height
 *    - data-scrollto-id: target element to scroll-to (not supported yet), by default scrolls to page top
 */

// UNDER CONSTRUCTION

export default class ScrollTo {

  constructor(debug = false) {

    this.debug = debug;

    this.debug && console.log('ScrollTo init');

    if (this.setVars()) {
      this.debug && console.log('ScrollTo vars init done');
      this.setEvents();
    }
  }

  setVars() {

    this.scrollToArr = document.querySelectorAll('.jsScrollTo');
    if (!this.scrollToArr) return false;

    return true;
  }

  setEvents() {
    this.scrollToArr.each((itemEl) => {
      itemEl.addEvent('click.ScrollTo', (e) => {
        this.debug && console.log('ScrollTo clicked');
        e.preventDefault();
        this.onClick(e);
      });
    });
  }

  onClick(e) {
    e.preventDefault();

    let fps = e.target.getAttribute('data-scrollto-fps');
    fps = fps ? parseInt(fps) : 30;

    const scrollToTargetId = e.target.getAttribute('data-scrollto-id');

    let scrollToOffset = e.target.getAttribute('data-scrollto-offset');
    scrollToOffset = scrollToOffset ? parseInt(scrollToOffset) : 0;

    let scrollToDuration = null;
    let scrollToSpeed = e.target.getAttribute('data-scrollto-speed');
    if (!scrollToSpeed) {
      scrollToDuration = e.target.getAttribute('data-scrollto-duration');
      scrollToDuration = scrollToDuration ? parseFloat(scrollToDuration) : 100;
    }

    this.debug && console.log('ScrollTo fps', fps);
    this.debug && console.log('ScrollTo scrollToTargetId', scrollToTargetId);


    /**
     * Just checking how requestAnimationFrame works
     * looking for good enoung alternative to anime.js - just for scrolling
     */

    function animate(callback, fps, props) {
      fps = fps || 30;

      let raFrameId = false;
      const interval = 1000/fps;

/*
      // alternative version with timeout - wrong timings
      const tick = () => {
        raFrameId = requestAnimationFrame(tick);
        callback(props);
        console.log('RAF call - scrollDuration / interval / timePassed', scrollToDuration, interval, Math.round(performance.now() - props.t0));
      }

      setTimeout(() => {
        tick()
      }, 1000/fps);
 */

      // alternative version without timeout
      let last = Date.now(); // let last = performance.now();
      const tick = () => {
        raFrameId = requestAnimationFrame(tick);
        const now =  Date.now(); // const now = performance.now();
        const elapsed = now - last;

        if (elapsed > interval) {
          last = now - (elapsed - interval); // correction for ticks missing its time slots
          // console.log('RAF call', interval, Math.round(performance.now() - props.t0));
          callback(props);
        }
      }
      tick();


      return {
        stop: () => {
          cancelAnimationFrame(raFrameId);
          raFrameId = false;
        },

        resume: () => {
          if (raFrameId === false)
            tick();
        }
      }
    }

    const scrollPosition = window.document.documentElement.scrollTop || window.document.body.scrollTop;
    let steps = 0;

    this.FRAMERATE = fps;
    this.STEP = scrollToSpeed;
    if (!scrollToSpeed) {
      this.STEP = (scrollPosition - scrollToOffset) / ((scrollToDuration / 1000) * this.FRAMERATE);
    }

    const scrollFunc = ({ step, totalSteps, offset }) => {
      const scrollTopCurrent = document.documentElement.scrollTop || document.body.scrollTop;

      let stepCorrected = step;
      if (!scrollToSpeed) {
        stepCorrected = (scrollTopCurrent) / ((scrollToDuration / 1000) * this.FRAMERATE - steps);
      }
      this.debug && console.log('scrollTop - moving', stepCorrected);

      if (scrollTopCurrent > offset) {
      // if (steps < totalSteps) {
        // this.debug && console.log('scrollTop - moving', scrollTopCurrent, stepCorrected, Math.round(performance.now() - t0));
        document.documentElement.scrollTop = document.body.scrollTop = (scrollTopCurrent - stepCorrected);
        steps++;
      }
      else {
        Animator.stop();

        const t1 = performance.now();
        this.debug && console.log('scrollTop - stop / scrollTop / steps / framerate / requestedDuration / realDuration',
          scrollTopCurrent + "px", Math.round(step) + "px/step", steps + "steps", totalSteps + "totalSteps", this.FRAMERATE + 'fps', scrollToDuration + 'ms', Math.round(t1 - t0) + 'ms');
      }
    };

    this.debug && console.log('scrollPosition', scrollPosition + "px");
    const t0 = performance.now();
    const Animator = animate(scrollFunc, this.FRAMERATE, {
      step:       this.STEP,
      totalSteps: (scrollToDuration / 1000) * this.FRAMERATE,
      offset:     0 + scrollToOffset,
      t0:         t0,
    });

    /**
     * End RAF testing
     */


    // this.debug && console.log('ScrollTo params: ', scrollElement, scrollToOffset, scrollToDuration);
    // const scrollElement = window.document.scrollingElement
    //   || window.document.body || window.document.documentElement;
    // anime({
    //   targets: scrollElement,
    //   scrollTop: 0 + scrollToOffset,
    //   duration: scrollToDuration,
    //   easing: 'easeInOutQuad',
    // });

    // TweenLite.killTweensOf(window);
    // TweenLite.to(window, scrollToSpeed, {
    //     // scrollTo: { y:"#"+scrollToTargetId, offsetY:scrollToOffset },
    //   scrollTo: { y: scrollToTargetId ? '#' + scrollToTargetId : 0, offsetY: scrollToOffset },
    //   ease: Quad.easeInOut,
    // });
  }
}
