import anime from 'animejs/lib/anime.es';

export default class Hamburger {

  constructor(debug = false) {

    this.debug = debug;
    this.TRANSITION_TIME  = 350;

    if (this.debug) console.log('Hamburger init');

    if (this.setVars()) {
      if (this.debug) console.log('Hamburger vars init done');
      this.setEvents();
    }
  }

  setVars()
  {
    this.hamburgerEl = document.querySelector('.jsHamburger');
    if (!this.hamburgerEl) return false;

    this.menuEl = document.querySelector('[data-hamburger-menu]');
    if (!this.menuEl) return false;

    return true;
  }

  setEvents()
  {
    this.hamburgerEl.addEvent('click.Hamburger', (e) => {
      e.preventDefault();

      this.onClick();
    });
  }

  onClick()
  {
    this.toggleActive();
  }

  toggleActive()
  {
    if (this.hamburgerEl.hasClass('active')) {
      this.hamburgerEl.removeClass('active');
      this.menuEl.removeClass('active');

      anime({
        targets: this.menuEl,
        maxHeight: 0,
        opacity: 0,
        duration: this.TRANSITION_TIME,
        easing: 'easeInOutQuad',
        // fix for desktop menu disappearing after hamburger open/close
        complete: () => {
          this.menuEl.removeAttribute('style');
        },
      });

    } else {

      this.hamburgerEl.addClass('active');
      this.menuEl.addClass('active');


      anime({
        targets: this.menuEl,
        maxHeight: this.getMenuHeight(),
        opacity: 1,
        duration: this.TRANSITION_TIME,
        easing: 'easeInOutQuad',
      });
    }
  }


  getMenuHeight()
  {
    // when menu is shorter than ~100vh
    // this.menuEl.style.maxHeight = 'initial';
    // const menuHeight = this.menuEl.offsetHeight;
    // this.menuEl.style.maxHeight = null;

    // assuming the menu will always be higher than view height
    const topMenuStyle = window.getComputedStyle(this.menuEl);
    const menuHeight = 'calc(100vh - ' + topMenuStyle.getPropertyValue('top') + ')';

    return menuHeight;
  }
}
