<template>
  <div>
    <div class="donut__svgWrapper">
      <div class="donut__svgInner">
        <div class="donut__circleBack"></div>

        <svg
          xmlns="http://www.w3.org/2000/svg" version="1.1"
          height="600" width="600"
          viewBox="0 0 600 600"
          class="donut__svg"
        >
          <defs>
            <linearGradient id="Gradient1" gradientTransform="rotate(90)">
              <stop offset="0%" :stop-color="colors[2]" />
              <stop offset="100%" :stop-color="colors[1]" />
            </linearGradient>
            <linearGradient id="Gradient2" gradientTransform="rotate(90)">
              <stop offset="0%" :stop-color="colors[0]" />
              <stop offset="100%" :stop-color="colors[1]" />
            </linearGradient>
            <pattern id="Pattern" x="0" y="0" width="600" height="600" patternUnits="userSpaceOnUse">
              <g transform="rotate(0, 300, 300)">
                <rect shape-rendering="crispEdges" x="0" y="0" width="300" height="600" fill="url(#Gradient1)" />
                <rect shape-rendering="crispEdges" x="300" y="0" width="300" height="600" fill="url(#Gradient2)" />
              </g>
            </pattern>
          </defs>

          <path id="arc5" style="stroke: url(#Pattern);" fill="transparent"
                class="donut__animated"
                stroke-dasharray="1570 1570"
                :stroke-dashoffset="arcLength"
                stroke-width="60" d="M 297 51 A 250 250 0 1 1 296.99 51" />
        </svg>

        <div class="donut__numbersBack">
          <div class="donut__number">
            <div class="donut__numberCounter"
                 data-odometer
                 :aria-label="title + ': ' + number + unit">
              {{ number }}
            </div>
            <div class="donut__numberUnit">
              {{ unit }}
            </div>
          </div>
        </div>

        <div class="donut__rings">
          <div class="donut__ringsInner">
            <svg xmlns="http://www.w3.org/2000/svg"
                 width="234" height="234" viewBox="0 0 234 234"
                 class="donut__ringsSvg">
              <g>
                <circle cx="117" cy="117" r="113" stroke="#3f4448" stroke-opacity="0.1" stroke-width="1" fill="none" />
                <circle cx="117" cy="117" r="104" stroke="#3f4448" stroke-opacity="0.15" stroke-width="1" fill="none" />
                <circle cx="117" cy="117" r="95" stroke="#3f4448" stroke-opacity="0.2" stroke-width="1" fill="none" />
                <circle cx="117" cy="117" r="86" stroke="#3f4448" stroke-opacity="0.25" stroke-width="1" fill="none" />
                <circle cx="117" cy="117" r="77" stroke="#3f4448" stroke-opacity="0.3" stroke-width="1" fill="none" />
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>

    <div class="donut__title">
      {{ title }}
    </div>
  </div>
</template>


<script>

  import Odometer from 'odometer';
  import 'odometer/themes/odometer-theme-minimal.css';

  import { /* debug,  */debugVerbose } from '../../debug';

  export default {
    props:
    {
      percentage: {
        type: Number,
        default: 0,
      },
      rotation: {
        type: Number,
        default: 0,
      },
      number: {
        type: Number,
        default: 0,
      },
      unit: {
        type: String,
        default: '',
      },
      title: {
        type: String,
        default: '',
      },
      colors: {
        type: Array,
        default: () => ['#41ab4f', '#64B44B', '#d5d33b'],
      },
    },

    data() {
      return {
        circleLength: 1570,
        arcLength: 1570,
        od: null,
      }
    },

    mounted()
    {
      debugVerbose && console.log('donut-chart mounted');

      this.initMutationObserver();
      this.initOdometer();

      this.$nextTick(() => {
        this.calcArcLength();
      });
    },

    beforeDestroy() {
      this.observer.disconnect();
    },

    methods: {
      initMutationObserver()
      {
        this.observer = new MutationObserver(mutations => {
          mutations.forEach((m) => {
            const newValue = m.target.getAttribute(m.attributeName);
            this.$nextTick(() => {
              this.doTheAnimation(newValue.indexOf('animation') !== -1);
            });
          });
        });

        this.observer.observe(this.$el, {
          attributes: true,
          attributeOldValue : true,
          attributeFilter: ['class'],
        });
      },

      initOdometer()
      {
        this.od = new Odometer({
          el: this.$el.querySelector('[data-odometer]'),
          // Any option (other than auto and selector) can be passed in here
          format: '( ddd).dd',
              // Format    -  Example
              // (,ddd)    -  12,345,678
              // (,ddd).dd -  12,345,678.09
              // (.ddd),dd -  12.345.678,09
              // ( ddd),dd -  12 345 678,09
              // d         -  12345678
          // formatFunction: ,
          theme: 'minimal',
          duration: 2500,
          // value: this.number,
          // animation: 'count', - just simple nothing
          animation: null,
        });
        this.od.render();
        // this.od.update(this.number);
        // alternative
        // this.$el.querySelector('[data-odometer]').innerHTML = this.number;
      },

      calcArcLength()
      {
        const percentage = Math.max(0, Math.min(100, this.percentage));
        this.arcLength = ((100 - percentage) / 100) * this.circleLength;
      },

      doTheAnimation(show)
      {
        // added some random delay not to kill the CPU when there are multiple donuts on page in the same area
        if (show) {
          setTimeout(() => {
            this.od.animation = null,
            this.od.update(this.number);
            this.calcArcLength();
          }, Math.floor(Math.random() * 500));
        }
        else {
          setTimeout(() => {
            this.od.animation = 'count',
            this.od.update(0);
            this.arcLength = 1570;
          }, Math.floor(500 + Math.random() * 500));
        }
      },
    }
  };

</script>


<style scoped lang="scss">

  .donut {
    $p: &;
    $c-steel-gray: #3f4448;

    &__svgWrapper {
      position: relative;
      padding: 47px;
    }

    &__circleBack {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 50%;
      left: 50%;
      width: percentage( 175 / 233 );
      height: percentage( 175 / 233 );
      border-radius: 50%;
      background-color: rgba($c-steel-gray, .02);
      transform: translate(-50%, -50%);
    }

    &__svg {
      display: block;
      position: relative;
      width: 100%;
      height: auto;
    }

    &__ringsSvg {
      display: block;
    }

    &__numbersBack {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 50%;
      left: 50%;
      width: percentage( 116 / 233 );
      height: percentage( 116 / 233 );
      border-radius: 50%;
      background-color: #fff;
      color: #41ad4f;
      font-family: Roboto, Tahoma, Arial;
      font-size: 30px;
      font-weight: 300;
      transform: translate(-50%, -50%);
      box-shadow: 0 5px 10px rgba(63, 68, 72, .05);
      z-index: 1;
      // overflow: hidden;

      @media (max-width: 768px) {
        font-size: 24px;
      }

      @media (max-width: 600px) {
        font-size: 26px;
      }
    }

    &__number {
      // max-width: 80%;
      display: flex;
      // overflow: hidden;
      border-radius: 50%;
      // overflow: hidden;
      // max-width: 100%;
    }

    // &__numberUnit,
    // &__numberCounter {
    //   display: inline-block
    // }

    // &__numberCounter {
    //   overflow: hidden;
    // }

    &__animated {
      // animation: dash 5s forwards;
      transition: stroke-dashoffset 2.5s;
    }

    &__rings {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
      // display: none;

      // width: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    // &__circleBack {
    //   // display: none;
    // }

    // &__svgInner {
    //   overflow: hidden;
    // }

    &__ringsInner {
      display: inline-block;
      transition: opacity .3s, transform .8s;
      transform: scale(.5);
      opacity: 0;
    }

    &__svgWrapper {
      &.active,
      &:hover {
        #{$p}__ringsInner {
          transform: scale(1);
          opacity: 1;
        }
      }
    }

    &__title {
      // margin-top: -16px;
      text-align: center;
      color: $c-steel-gray;
      // font-size: 16px;
      // font-weight: 400;
      // line-height: 28px;
    }
  }
</style>