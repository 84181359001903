import Vue from 'vue';
import Vuelidate from 'vuelidate';
// import VeeValidate, { Validator } from 'vee-validate';
// import VueRouter from 'vue-router'
// import store from './store/index';
// import EventBus from './components/eventbus/EventbusComponent.vue';


// i18n
// require('./i18n/index.js');

// components
require('./components/index.js');

// filters
// require('./filters/index.js');

// Vue.use(VueRouter);

Vue.use(Vuelidate);

// Vue.use(VeeValidate, {
//    locale: 'pl',
//    classes: true
// });


// var router = new VueRouter({
//    mode: 'history',
//    routes: []
// });

// window.AppRouter = router;

const ready = (fn) => {
  if (document.attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading') {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
};

// maybe a separate mounting point for every vue component?
// https://vuejs.org/v2/api/#vm-mount

// var MyComponent = Vue.extend({
//   template: '<div>Hello!</div>'
// })

// create and mount to #app (will replace #app)

// import CardSlider from './components/card-slider/CardSlider.vue';

// will not work this way but I will find a way
// maybe just a foreach:
// window.App = new Vue({
//   el: var-mounting-id,
// will be enough?
//
// ready(() => {
//   setTimeout(() => {
//     let CardSliderInstance = Vue.component('card-slider', Vue.extend(CardSlider));
//     // var CardSliderInstance = Vue.extend(CardSlider);
//     new CardSliderInstance().$mount('#card-slider');
//   }, 0)
// });

ready(() => {
  setTimeout(() => {
    // console.log('document vue init');
    window.App = new Vue({
      el: '#app_handle',
      mounted() {
        // console.log('document vue ready mounted');
        const event = new CustomEvent('vueMounted', { detail: 'vue' });
        window.dispatchEvent(event);
      },
    });
  }, 0)
});


