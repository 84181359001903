export default class Socials
{
  constructor()
  {
    if (!this.setVars()) return;

    this.checkLocation();
    this.setEvents();
  }

  setVars()
  {
    this.share = document.querySelector('.toolbar__itemLink--share');
    if (!this.share) return;

    this.socials = document.querySelector('.socials');
    if (!this.socials) return;

    this.items = [...this.socials.querySelectorAll('.socials__item')];

    this.base = 'http://www.sidf.gov.sa/';

    this.linkedin = 'https://www.linkedin.com/shareArticle/?mini=true&url=';
    this.facebook = 'https://www.facebook.com/sharer/sharer.php?u=';
    this.twitter = 'https://twitter.com/intent/tweet?original_referer=';

    return true;

  }

  setEvents()
  {
    this.share.addEventListener('click', () => {
      this.socials.classList.toggle('socials--active');
    });

    this.items.forEach( item => {
      const social = item.getAttribute('data-social');
      this.checkSocial(item, social);
    }) 
  }


  checkLocation()
  {
    this.host = window.location.host.split(':')[0];
    if (this.host !== 'localhost') {
      this.base = window.location.href;
    }

  }

  checkSocial(item, data)
  {
    switch(data) {
      case 'facebook':
        item.addEventListener('click', () => {
          window.open(this.facebook + this.base, '_blank', 'toolbar=0,location=0,menubar=0');
        })
        break;
      case 'twitter':
        item.addEventListener('click', () => {
          window.open(this.twitter + this.base, '_blank', 'toolbar=0,location=0,menubar=0');
        })
        break;
      case 'linkedin':
        item.addEventListener('click', () => {
          window.open(this.linkedin + this.base, '_blank', 'toolbar=0,location=0,menubar=0');
        })
        break;
      default: 
        break;
    }
  }
}
