export default class FontSize
{
  constructor()
  {
    if (!this.setVars()) return;

    this.setEvents();
  }

  setVars()
  {
    this.toolbar = document.querySelector('.toolbar__items');
    if (!this.toolbar) return;

    this.plusFont = this.toolbar.querySelector('.toolbar__itemLink--a-plus');
    this.minusFont = this.toolbar.querySelector('.toolbar__itemLink--a-minus');

    this.header = document.querySelector('.header');
    this.logo = this.header.querySelector('.header__logo');

    this.html = document.querySelector('html');

    this.defaultSize = 62.5;
    this.diff = 10;
    this.unit = '%';
    this.counter = 0;

    return true;
  }

  setEvents()
  {
    this.plusFont.addEventListener('click', () => this.biggerFontSize());
    this.minusFont.addEventListener('click', () => this.smallerFontSize());
  }

  biggerFontSize()
  {
    console.log(this.logo);
    if (this.counter <= 1) {
      this.counter++;
      this.defaultSize = this.defaultSize + this.diff;
      this.html.style.fontSize = this.defaultSize + this.unit;
      this.plusFont.classList.remove('toolbar__itemLink--a-plus--deactivate');
      this.minusFont.classList.remove('toolbar__itemLink--a-minus--deactivate');
      this.logo.classList.add('.header__logo--small');
    } else if (this.counter == 2) {
      this.plusFont.classList.add('toolbar__itemLink--a-plus--deactivate');
    }
  } 

  smallerFontSize()
  {
    if (this.counter >= -1) {
      this.counter--;
      this.defaultSize = this.defaultSize - this.diff;
      this.html.style.fontSize = this.defaultSize + this.unit;
      this.minusFont.classList.remove('toolbar__itemLink--a-minus--deactivate');
      this.plusFont.classList.remove('toolbar__itemLink--a-plus--deactivate');
    } else if (this.counter === -2) {
      this.minusFont.classList.add('toolbar__itemLink--a-minus--deactivate');
    }
  }
}
