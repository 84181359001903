import Vue from 'vue';
import VuePortal from '@linusborg/vue-simple-portal';
import CardSlider from './card-slider/CardSlider.vue';
import DonutChart from './donut-chart/DonutChart.vue';
import HomeSlider from './home-slider/HomeSlider.vue';
import SimpleTabs from './simple-tabs/SimpleTabs.vue';
import VideoPlayer from './video-player/VideoPlayer.vue';
import PageForm from './page-form/PageForm.vue';
import ContactForm from './contact-form/ContactForm.vue';


// import VueUploadComponent from 'vue-upload-component';
// import VueSelect from 'vue-select';

// require('./home-slider/index.js');
// require('./here-map/index.js');





// import Vuelidate from 'vuelidate';
// import vSelect from 'vue-select';
// import axios from 'axios';

// import PerfectScrollbar from 'vue2-perfect-scrollbar';
// import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';


// import 'odometer/themes/odometer-theme-minimal.css';


// Vue.use(Vuelidate, axios);
// Vue.use(PerfectScrollbar);
Vue.use(VuePortal);

// Vue.component('v-select', vSelect);
// Vue.component('changes-component', ChangesComponent);
Vue.component('card-slider', CardSlider);
Vue.component('donut-chart', DonutChart);
Vue.component('home-slider', HomeSlider);
Vue.component('simple-tabs', SimpleTabs);
Vue.component('video-player', VideoPlayer);
Vue.component('page-form', PageForm);
Vue.component('contact-form', ContactForm);


// Vue.component('form-component', require('./form-component/FormComponent.vue'));


