<template>
  <div>
    <div class="iconCard__wrapper rs_preserve">
      <a
        v-for="(tab, ktab) in tabs"
        :key="ktab"
        class="iconCard"
        :class="{ active : activeSlide == ktab }"
        :href="tab.buttonLink"
        @click.prevent="changeTab(ktab)"
      >
        <div class="iconCard__inner">
          <div
            class="iconCard__icon icon"
            :class="'icon-' + tab.icon"
          ></div>
          <div class="iconCard__title heading__h3">
            {{ tab.title }}
          </div>
        </div>
      </a>
    </div>

    <transition
      :name="transition"
      mode="out-in"
    >
      <div
        v-for="(tab, ktab) in tabs"
        v-if="activeSlide == ktab"
        :key="ktab"
        class="overview__slide overview__slide pageGeneric__content"
      >
        <div class="overview__slideInner rs_preserve" v-html="tab.richContent"></div>
      </div>
    </transition>
  </div>
</template>


<script>

  export default {
    props:
      {
        tabs: {
          type: Object,
          default: null,
        },
      },
    data() {
      return {
        activeSlide: 'Vision',
      };
    },
    computed: {
      transition: function () {
        return this.textDirection === 'ltr' ? 'slide-fade-right' : 'slide-fade-left';
      },
      textDirection: function () {
        return getComputedStyle(document.querySelector('html')).direction;
      },
    },
    mounted()
    {
      // console.log('simple-slider mounted');
      // console.log('text dir', this.textDirection);
      this.setupSelectedTab();
    },
    methods: {
      changeTab(tab)
      {
        setTimeout(() => {
          window.scroll({ 
            top: this.$el.querySelector('.pageGeneric__content').offsetTop - 120, 
            behavior: 'smooth'
          });
        }, 300);

        this.activeSlide = tab;
        this.recalculateWrapperHeight();
      },
      recalculateWrapperHeight()
      {
        setTimeout(() => {
          this.$el.style.minHeight = 'auto';
          this.$el.style.minHeight = `${this.$el.offsetHeight}px`; // height jump problem fix
        }, 700); // slide transition length + some margin
      },
      setupSelectedTab()
      {
        Object.keys(this.tabs).forEach((tabKey) => {
          if (this.tabs[tabKey].active === true) {
            this.activeSlide = tabKey;
          }
        });
      },
    },
  };

</script>

<style scoped lang="scss">

  // .simpleTabs {
  // }

  .slide-fade-left-enter-active,
  .slide-fade-left-leave-active,
  .slide-fade-right-enter-active,
  .slide-fade-right-leave-active {
    transition: 0.5s;
  }

  .slide-fade-left-enter {
    opacity: 0;
    transform: translate3d(-100px, 0, 0);
  }
  .slide-fade-left-leave-to {
    opacity: 0;
    transform: translate3d(100px, 0, 0);
  }


  .slide-fade-right-enter {
    opacity: 0;
    transform: translate3d(100px, 0, 0);
  }
  .slide-fade-right-leave-to {
    opacity: 0;
    transform: translate3d(-100px, 0, 0);
  }

</style>
