export default class Contrast
{
  constructor()
  {
    if (!this.setVars()) return;

    this.setInitiatlState();
    this.setEvents();
  }

  setVars()
  {
    this.toolbar = document.querySelector('.toolbar__items');
    if (!this.toolbar) return;

    this.switch   = this.toolbar.querySelector('.toolbar__itemLink--colors');
    this.body     = document.querySelector('body');

    return true;
  }

  setEvents()
  {
    this.switch.addEventListener('click', e => {
      e.preventDefault();
      this.changeContrast();
    });
  }

  changeContrast()
  {
    if (this.body.classList.contains('contrast')) {
      this.body.classList.remove('contrast');
      localStorage.removeItem('contrast');
    } else {
      this.body.classList.add('contrast');
      localStorage.setItem('contrast', 1);
    }
  }

  setInitiatlState()
  {
    let state = localStorage.getItem('contrast');

    if (state) {
      this.body.classList.add('contrast');
    } else {
      this.body.classList.remove('contrast');
    }
  }
}
