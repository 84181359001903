import Swiper from 'swiper/js/swiper';
import 'swiper/css/swiper.css';


export default class Carousel
{
  constructor()
  {
    if (!this.setVars()) return;

    this.initialState();
    this.checkWidth();
    this.buildMainImageSlider();
    this.buildThumbsSlider();
    this.buildTextSlider();
    this.setEvents();
  }

  setVars()
  {
    this.carousel         = document.querySelector('.carousel');
    if (!this.carousel) return;

    this.language         = document.querySelector('html').getAttribute('dir');

    this.buttonNext       = this.carousel.querySelector('.carousel__next');
    this.buttonPrev       = this.carousel.querySelector('.carousel__prev');

    this.images           = this.carousel.querySelector('.carousel__images');
    this.thumbs           = this.carousel.querySelector('.carousel__thumbs');
    this.text             = this.carousel.querySelector('.carousel__info');

    this.numberOfSlides   = this.images.querySelectorAll('.swiper-slide').length;

    this.activeSlideIndex = 0;
    this.lastSlideIndex   = this.numberOfSlides - 1;

    this.swpierThumbs     = null;

    this.settings         = {
      delayTime: 2000,
      speed: 2000,
      atts: {
        activeSlide   : 'data-swiper-slide-index',
      },
      breakpoints: {
        largePhone    : 480,
        tablet        : 767,
        largeTablet   : 1023,
        laptop        : 1279,
      }
    }

    return true;
  }

  setEvents()
  {
    window.addEventListener('resize', () => {
      this.checkWidth();
    });

    // this.swpierThumbs.on('slideChange', () => {
    //   this.activeSlideIndex = this.swpierThumbs.realIndex;

    //   this.swiperImages.slideToLoop(this.activeSlideIndex);
    //   this.swiperText.slideToLoop(this.activeSlideIndex);
    // })

    this.swiperImages.on('slideChange', () => {
      this.changeSlides(this.swiperImages);
    });

    this.swpierThumbs.on('click', (e) => {
      this.changeSlidesOnClick(e);
    })
  }

  initialState()
  {
    if (this.language === 'rtl') {
      this.images.setAttribute('dir', 'rtl');
      this.thumbs.setAttribute('dir', 'rtl');
      this.text.setAttribute('dir', 'rtl');
    } else {
      this.images.setAttribute('dir', 'ltr');
      this.thumbs.setAttribute('dir', 'ltr');
      this.text.setAttribute('dir', 'ltr');
    }
  }

  buildMainImageSlider()
  {
    this.swiperImages = new Swiper(this.images, {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 1,
      normalizeSlideIndex: true,
      autoplay: {
        delay: this.settings.delayTime,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: this.buttonNext,
        prevEl: this.buttonPrev,
      },
    });
  }

  buildThumbsSlider()
  {
    this.swpierThumbs = new Swiper(this.thumbs, {
      spaceBetween: 16,
      slidesPerView: 4,
      initialSlide: 1,
      loop: true,
      normalizeSlideIndex: true,
      navigation: {
        nextEl: this.buttonNext,
        prevEl: this.buttonPrev,
      },
    });
  }

  buildTextSlider()
  {
    this.swiperText = new Swiper(this.text, {
      loop: true,
      simulateTouch: false,
      navigation: {
        nextEl: this.buttonNext,
        prevEl: this.buttonPrev,
      },
    });
  }

  changeSlides(slider)
  {
    this.activeSlideIndex = slider.realIndex;
    this.thumbSlideIndex = this.activeSlideIndex + 1;

    this.swiperText.slideToLoop(this.activeSlideIndex);
    this.swpierThumbs.slideToLoop(this.thumbSlideIndex);
  }

  changeSlidesOnClick(event)
  {
    this.activeSlideIndex = parseInt(event.target.parentElement.getAttribute(this.settings.atts.activeSlide));
    this.thumbSlideIndex = this.activeSlideIndex + 1;

    this.swpierThumbs.slideToLoop(this.thumbSlideIndex);
    this.swiperImages.slideToLoop(this.activeSlideIndex);
    this.swiperText.slideToLoop(this.activeSlideIndex);
  }

  checkWidth()
  {
    const width = window.innerWidth;

    if (width < this.settings.breakpoints.largePhone) {
      if (this.swpierThumbs !== null) {
        this.swpierThumbs.destroy(true, false);
      }
    } else {
      if (this.swpierThumbs === null) {
        this.buildThumbsSlider();
      }
    }
  }
}
